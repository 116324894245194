import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl } from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import { useRoleCheck } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';
import ColegiosService, { Colegio } from '../services/colegios.service';

import './colegios.page.css';

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Colegios</Typography>
];

const Content = () => {
  useRoleCheck(['ADM']);
  
  const [refresh, setRefresh] = useState<number>(0);
  const [openDialogCrearColegio, setOpenDialogCrearColegio] = useState(false);
  const [openDialogModificarColegio, setOpenDialogModificarColegio] = useState(false);
  const [colegios, setColegios] = useState<Colegio[]>([]);
  const [colegio, setColegio] = useState<Colegio | undefined>();
  const [createId, setCreateId] = useState<string>('');
  const [nombreColegio, setNombreColegio] = useState<string>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const [updateId, setUpdateId] = useState<string>('');

  const fnOpenDialogCrearColegio = () => {
    setOpenDialogCrearColegio(true);
  };

  const fnOpenDialogModificarColegio = (id: string, nombre: string) => {
    setUpdateId(id);
    setNombreColegio(nombre);
    setOpenDialogModificarColegio(true);
  };

  const fnCrearColegio = () => {
    setUpdateId('');
    setDeleteId('');

    const colegio: Colegio = {
      id: createId,
      nombre: nombreColegio
    };
    
    setColegio(colegio);
    fnCloseAllDialogs();
  };
  
  const fnModificarColegio = () => {
    setCreateId('');
    setDeleteId('');

    const colegio: Colegio = {
      id: updateId,
      nombre: nombreColegio
    };
    
    setColegio(colegio);
    fnCloseAllDialogs();
  };
  
  const fnCloseAllDialogs = () => {
    setOpenDialogCrearColegio(false);
    setOpenDialogModificarColegio(false);
  };

  useEffect(() => {
    if (deleteId !== '') {
      (async () => {
        const response = await ColegiosService.eliminar(deleteId);
        setDeleteId('');
        setRefresh((refresh) => refresh + 1);
      })();
    }

    if (typeof colegio === 'object') {
      if (createId !== '') {
        (async () => {
          const response = await ColegiosService.crear(colegio);
          setRefresh((refresh) => refresh + 1);
        })();

        setCreateId('');
        setNombreColegio('');
      }
      
      if (updateId !== '') {
        (async () => {
          const response = await ColegiosService.actualizar(colegio);
          setRefresh((refresh) => refresh + 1);
        })();

        setUpdateId('');
      }

      setColegio(undefined);
    }
  }, [colegio, deleteId]);

  useEffect(() => {
    (async () => {
      const dataColegios = await ColegiosService.listar();
      setColegios(dataColegios);
    })();
  }, [refresh]);
  
  return (
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrearColegio} >Crear</Button>
      <Dialog open={openDialogCrearColegio} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR COLEGIO</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Complete los siguientes datos:
          </DialogContentText><br /> */}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="rbd"
              label="RBD"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setCreateId(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nombre del colegio"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setNombreColegio(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrearColegio}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogModificarColegio} onClose={fnCloseAllDialogs}>
        <DialogTitle>MODIFICAR COLEGIO</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nombre del colegio"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setNombreColegio(e.target.value)}
            value={ nombreColegio }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={fnModificarColegio}>Guardar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">RBD</TableCell>
                <TableCell align="center">NOMBRE</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {colegios.map((colegio: Colegio) => (
                <TableRow
                  key={`cole-${colegio.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {colegio.id}
                  </TableCell>
                  <TableCell align="center">{colegio.nombre}</TableCell>
                  <TableCell align="right">
                    <Button variant="contained" size="small" startIcon={<SettingsRoundedIcon />} onClick={() => fnOpenDialogModificarColegio(colegio.id, colegio.nombre)}>Modificar</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={ () => setDeleteId(colegio.id) }>Eliminar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              {/* <TableRow>
                <TableCell align="center" colSpan={3}>
                  <div className='pagination-container'>
                    <Stack spacing={5}>
                      <Pagination count={10} shape="rounded" page={1} />
                    </Stack>
                  </div>
                </TableCell>
              </TableRow> */}
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

const Colegios = () => {
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content />} />
  );
};

export default Colegios;
