import * as React from 'react';

import { Box, CssBaseline, Breadcrumbs, Toolbar, Typography } from '@mui/material';

import Menu, { DrawerHeader, AppBar } from '../menu/menu.component';

import './page.component.css';

const title = 'Plataforma de Consultas Estudiantiles'; // NOTA: parece que este title NO se está utiliznado.

const Page = ({children, breadcrumbs}: {children: React.ReactNode, breadcrumbs: React.ReactNode[]}) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Menu />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {
          breadcrumbs.length > 0 && <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        }
        {children}
      </Box>
    </Box>
  );
}

const PageEncuesta = ({children}: {children: React.ReactNode}) => {
  return (
    <section>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" noWrap component="div">{title}</Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {children}
        </Box>
      </Box>
    </section>
  );
}

export {
  Page as default,
  PageEncuesta
};
