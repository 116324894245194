import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl } from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import { useRoleCheck } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';
import NivelesService, { Nivel } from '../services/niveles.service';

import './niveles.page.css';

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Niveles</Typography>
];

const Content = () => {
  useRoleCheck(['ADM']);
  
  const [refresh, setRefresh] = useState<number>(0);
  const [openDialogCrearNivel, setOpenDialogCrearNivel] = useState(false);
  const [openDialogModificarNivel, setOpenDialogModificarNivel] = useState(false);
  const [niveles, setNiveles] = useState<Nivel[]>([]);
  const [nivel, setNivel] = useState<Nivel | undefined>();
  const [deleteId, setDeleteId] = useState<string | undefined>();
  const [updateId, setUpdateId] = useState<string | undefined>();
  const [createId, setCreateId] = useState<string | undefined>();
  const [nombreNivel, setNombreNivel] = useState<string | undefined>();
  const [indice, setIndice] = useState<number | undefined>();

  const fnOpenDialogCrearNivel = () => {
    setOpenDialogCrearNivel(true);
  };

  const fnOpenDialogModificarNivel = (id: string | undefined, nombre?: string, indice?: number) => {
    setUpdateId(id);
    setNombreNivel(nombre);
    setIndice(indice);
    setOpenDialogModificarNivel(true);
  };

  const fnCrearNivel = () => {
    setUpdateId('');
    setDeleteId('');

    const nivel: Nivel = {
      id: createId,
      nombre: nombreNivel,
      indice: indice
    };
    
    setNivel(nivel);
    fnCloseAllDialogs();
  };
  
  const fnModificarNivel = () => {
    setCreateId('');
    setDeleteId('');

    const nivel: Nivel = {
      id: updateId,
      nombre: nombreNivel,
      indice: indice
    };
    
    setNivel(nivel);
    fnCloseAllDialogs();
  };
  
  const fnCloseAllDialogs = () => {
    setOpenDialogCrearNivel(false);
    setOpenDialogModificarNivel(false);
  };

  useEffect(() => {
    if (deleteId !== '') {
      (async () => {
        const response = await NivelesService.eliminar(deleteId);
        setDeleteId('');
        setRefresh((refresh) => refresh + 1);
      })();
    }

    if (typeof nivel === 'object') {
      if (createId !== '') {
        (async () => {
          const response = await NivelesService.crear(nivel);
          setRefresh((refresh) => refresh + 1);
        })();

        setCreateId('');
        setNombreNivel('');
        setIndice(0);
      }
      
      if (updateId !== '') {
        (async () => {
          const response = await NivelesService.actualizar(nivel);
          setRefresh((refresh) => refresh + 1);
        })();

        setUpdateId('');
      }

      setNivel(undefined);
    }
  }, [nivel, deleteId]);

  useEffect(() => {
    (async () => {
      const dataNiveles = await NivelesService.listar();
      setNiveles(dataNiveles);
    })();
  }, [refresh]);

  return(
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrearNivel} >Crear</Button>
      <Dialog open={openDialogCrearNivel} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR NIVEL</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Complete los siguientes datos:
          </DialogContentText><br /> */}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="id"
              label="ID"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setCreateId(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nombre del nivel"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setNombreNivel(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="index"
              label="Índice"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setIndice(Number(e.target.value))}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrearNivel}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogModificarNivel} onClose={fnCloseAllDialogs}>
        <DialogTitle>MODIFICAR NIVEL</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nombre del nivel"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setNombreNivel(e.target.value)}
            value={ nombreNivel }
          />
          <TextField
            autoFocus
            margin="dense"
            id="index"
            label="Índice"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setIndice(Number(e.target.value))}
            value={ indice }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={fnModificarNivel}>Guardar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">NOMBRE</TableCell>
                <TableCell align="center">ÍNDICE</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {niveles.map((nivel: Nivel) => (
                <TableRow
                  key={`cole-${nivel.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {nivel.id}
                  </TableCell>
                  <TableCell align="center">{nivel.nombre}</TableCell>
                  <TableCell align="center">{nivel.indice}</TableCell>
                  <TableCell align="right">
                    <Button variant="contained" size="small" startIcon={<SettingsRoundedIcon />} onClick={() => fnOpenDialogModificarNivel(nivel.id, nivel.nombre, nivel.indice)}>Modificar</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={ () => setDeleteId(nivel.id) }>Eliminar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              {/* <TableRow>
                <TableCell align="center" colSpan={3}>
                  <div className='pagination-container'>
                    <Stack spacing={5}>
                      <Pagination count={10} shape="rounded" page={1} />
                    </Stack>
                  </div>
                </TableCell>
              </TableRow> */}
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};
const Niveles = () => {
  return(
    <>
      <Page breadcrumbs={breadcrumbs} children={<Content />} />
    </>
  );
};

export default Niveles;
