import axios from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";

export interface Nivel {
  id: string | undefined;
  nombre?: string;
  indice?: number;
  activo?: number;
};

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class NivelesService {
  static async listar() {
    try {
      const response = await axios.get<Nivel[]>(`/niveles`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async crear(nivel: Nivel) {
    try {
      const response = await axios.post(`/niveles`, nivel, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async eliminar(id: string | undefined) {
    try {
      const response = await axios.delete(`/niveles/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async actualizar(nivel: Nivel) {
    try {
      const response = await axios.patch(`/niveles/${nivel.id}`, nivel, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default NivelesService;
