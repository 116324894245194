import { useNavigate } from 'react-router-dom';

export const useRoleCheck = (allowedRoles: string[]) => {
  const navigate = useNavigate();
  const userRole = sessionStorage.getItem('role');
  // console.log('userRole', userRole);

  // If the user doesn't have the necessary role, redirect to the login page
  if (!userRole || !allowedRoles.includes(userRole)) {
    navigate('/login');
  }
};

export const getRole = () => {
  return sessionStorage.getItem('role');
};

export const getUserId = () => {
  return sessionStorage.getItem('uid');
};

export const isADM = () => {
  return sessionStorage.getItem('role') === 'ADM' ? true : false;
};

export const isASG = () => {
  return sessionStorage.getItem('role') === 'ASG' ? true : false;
};

export const isCAA = () => {
  return sessionStorage.getItem('role') === 'CAA' ? true : false;
};