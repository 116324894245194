import { Routes, Route } from "react-router-dom";

import Inicio from "../pages/inicio.page";
import Login from "../pages/login.page";
import Usuarios from "../pages/usuarios.page";
import Colegios from "../pages/colegios.page";
import Estudiantes from "../pages/estudiantes.page";
import Cursos from "../pages/cursos.page";
import Niveles from "../pages/niveles.page";
import Asignaturas from "../pages/asignaturas.page";
import Formularios from "../pages/formularios.page";
import Encuesta from "../pages/encuesta.page";

const Router = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={<Login />}
      />
      <Route
        path="/"
        element={<Inicio />}
      />
      <Route
        path="/usuarios"
        element={<Usuarios />}
      />
      <Route
        path="/colegios"
        element={<Colegios />}
      />
      <Route
        path="/estudiantes"
        element={<Estudiantes />}
      />
      <Route
        path="/cursos"
        element={<Cursos />}
      />
      <Route
        path="/niveles"
        element={<Niveles />}
      />
      <Route
        path="/asignaturas"
        element={<Asignaturas />}
      />
      <Route
        path="/formularios"
        element={<Formularios />}
      />
      <Route
        path="/encuestas/:id"
        element={<Encuesta />}
      />
    </Routes>
  );
};

export default Router;
