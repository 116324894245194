import axios from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";

export interface Colegio {
  id: string;
  nombre: string;
  creado?: string;
  activo?: number;
}

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class ColegiosService {
  static async listar() {
    try {
      const response = await axios.get<Colegio[]>(`/colegios`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async crear(colegio: Colegio) {
    try {
      const response = await axios.post(`/colegios`, colegio, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async eliminar(id: string) {
    try {
      const response = await axios.delete(`/colegios/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async actualizar(colegio: Colegio) {
    try {
      const response = await axios.patch(`/colegios/${colegio.id}`, colegio, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default ColegiosService;
