// import * as React from 'react';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import FormatListNumberedRounded from '@mui/icons-material/FormatListNumberedRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import IntegrationInstructionsRoundedIcon from '@mui/icons-material/IntegrationInstructionsRounded';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';

import { useNavigate } from "react-router-dom";

import AuthService, { Credential } from '../../services/auth.service';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const title = 'Plataforma de Consultas Estudiantiles';

const nav = [
  {
    text: 'Inicio',
    link: '/',
    icon: <HomeRoundedIcon />,
    role: ['ADM', 'ASG', 'CAA']
  },
  {
    text: 'Usuarios',
    link: '/usuarios',
    icon: <PeopleAltRoundedIcon />,
    role: ['ADM']
  },
  {
    text: 'Colegios',
    link: '/colegios',
    icon: <SchoolRoundedIcon />,
    role: ['ADM']
  },
  {
    text: 'Estudiantes',
    link: '/estudiantes',
    icon: <GroupRoundedIcon />,
    role: ['ADM', 'ASG', 'CAA']
  },
  {
    text: 'Cursos',
    link: '/cursos',
    icon: <Diversity3RoundedIcon />,
    role: ['ASG', 'CAA']
  },
  {
    text: 'Niveles',
    link: '/niveles',
    icon: <FormatListNumberedRounded />,
    role: ['ADM']
  },
  {
    text: 'Asignaturas',
    link: '/asignaturas',
    icon: <MenuBookRoundedIcon />,
    role: ['ADM']
  },
  {
    text: 'Formularios',
    link: '/formularios',
    icon: <IntegrationInstructionsRoundedIcon />,
    role: ['ADM', 'ASG', 'CAA']
  },
  {
    text: 'Salir',
    link: '/login',
    icon: <ExitToAppRoundedIcon />,
    role: ['ADM', 'ASG', 'CAA']
  }
];

// const userRole = sessionStorage.getItem('role');

const Menu = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [role, setRole] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const fnLogout = () => {
    AuthService.logOut();
    navigate('/login');
  };

  useEffect(() => {
    setRole(sessionStorage.getItem('role'));
  }, []);
  
  return(
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">{title}</Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {nav.map((element, index) => {
            // Comprobar si el rol del usuario está en el array 'role' del elemento
            if (!role || !element.role.includes(role)) {
              // Si el rol del usuario no está en el array, no mostrar este elemento
              return null;
            }

            // Si el rol del usuario está en el array, mostrar el elemento como normalmente lo harías
            return (
              <ListItem key={element.text} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  component={element.text !== 'Salir' ? Link : 'button'} 
                  to={element.text !== 'Salir' ? element.link : '#'} 
                  onClick={element.text === 'Salir' ? fnLogout : undefined}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {element.icon}
                  </ListItemIcon>
                  <ListItemText primary={element.text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};

export {
  Menu as default,
  DrawerHeader,
  AppBar
};
