import axios from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";

export interface Asignatura {
  id?: number;
  nombre?: string;
  activo?: number;
};

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class AsignaturasService {
  static async listar() {
    try {
      const response = await axios.get<Asignatura[]>(`/asignaturas`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async crear(asignatura: Asignatura) {
    try {
      const response = await axios.post(`/asignaturas`, asignatura, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async eliminar(id: number = 0) {
    try {
      const response = await axios.delete(`/asignaturas/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async actualizar(asignatura: Asignatura) {
    try {
      const response = await axios.patch(`/asignaturas/${asignatura.id}`, asignatura, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default AsignaturasService;
