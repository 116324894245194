import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, RadioGroup, FormControlLabel, Radio, Slider, Button } from '@mui/material';
import { styled, Box } from '@mui/system';

import { PageEncuesta } from '../components/page/page.component';
import EncuestasService, { Encuesta, Respuesta } from '../services/encuestas.service';
import { Pregunta } from '../services/formularios.service';

import './encuesta.page.css';

const StyledSlider = styled(Slider)({
  width: 400,
  // width: '100%',
});

const Content = () => {
  const [encuesta, setEncuesta] = useState<Encuesta | undefined>();
  const [respuestas, setRespuestas] = useState<Respuesta[]>([]);
  const [enviar, setEnviar] = useState<boolean>(false);
  const [pantallaExito, setPantallaExito] = useState<boolean>(false);
  const [pantallaError, setPantallaError] = useState<boolean>(false);
  const [pantalla404, setPantalla404] = useState<boolean>(true);
  const params = useParams();

  const handleChange = (id: number, value: any) => {
    setRespuestas(prevRespuestas => 
      prevRespuestas.map(r => 
        r.id === id ? { ...r, calificacion: value } : r
      )
    );
  };

  const handleSubmit = () => {
    let respuestasValidas = true;

    if (encuesta!.asignatura) {
      // validación mayor a cero
      respuestas.forEach((respuesta) => {
        if (respuesta.calificacion === 0) {
          respuestasValidas = false;
        }
      });
    } else {
      // validación distinto de undefined o Number no isNan o igual a un cero o un uno.
      respuestas.forEach((respuesta) => {
        if (respuesta.calificacion === undefined) {
          respuestasValidas = false;
        }
      });
    }

    if (respuestasValidas) {
      setEnviar(true);
    }
  };

  useEffect(() => {
    if (enviar) {
      (async () => {
        const response = await EncuestasService.save(encuesta!, respuestas);
        
        if (response) {
          setPantallaExito(true);
        } else {
          setPantallaError(true);
        }
      })();
    }
  }, [enviar]);

  useEffect(() => {
    (async () => {
      try {
        const response: any = await EncuestasService.get(params.id!);
        
        if (response && response.status === 200) {
          setPantalla404(false);
          const encuesta: Encuesta = response.data;
          setEncuesta(encuesta);

          const respuestas = encuesta.preguntas!.map((pregunta: Pregunta) => {
            let calificacion = undefined; // Para que los campos tipo radio no tengan seleccionada ninguna opción.

            if (encuesta.asignatura) {
              calificacion = 0; // Para que los campos tipo porcentaje comiencen con una puntuación igual a cero.
            }

            const respuesta: Respuesta = {
              id: pregunta.id,
              pregunta: {
                id: pregunta.id
              },
              encuesta: {
                id: encuesta.id
              },
              calificacion: calificacion
            };

            return respuesta;
          });

          setRespuestas(respuestas);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '1200px',
      margin: '0 auto',
    }}>
      <section>
        { pantalla404 ? (
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h5" fontWeight="bold">La encuesta solicitada NO existe</Typography><br/>
            {/* Aquí puedes agregar un mensaje personalizado para el error 404 */}
          </div>
        ) : (
          <React.Fragment>
            <div style={{ textAlign: 'center' }}>
              <Typography variant="h5" fontWeight="bold">ENCUESTA</Typography><br/>
            </div>
            { !pantallaExito && !pantallaError && (
              <React.Fragment>
                <div>
                  {encuesta && respuestas.map((respuesta, index) => (
                    <React.Fragment key={respuesta.id}>
                      <Typography variant="h6">{index + 1}. {encuesta?.preguntas?.[index]?.pregunta}</Typography>
                      {encuesta!.asignatura ? (
                          <StyledSlider name={`pregunta${respuesta.id}`} value={respuesta.calificacion || 0} step={1.0} min={0.0} max={7.0} onChange={(e:any, newValue:any) => handleChange(respuesta.id, newValue)} valueLabelDisplay="auto" marks />
                        ) : (
                          <RadioGroup name={`pregunta${respuesta.id}`} value={respuesta.calificacion || undefined} onChange={(e:any) => handleChange(respuesta.id, e.target.value)}>
                            <FormControlLabel value={1} control={<Radio />} label="Sí" />
                            <FormControlLabel value={0} control={<Radio />} label="No" />
                          </RadioGroup>
                      )}
                      <br />
                      {((encuesta!.asignatura && respuestas[index].calificacion === 0) || (!encuesta!.asignatura && respuestas[index].calificacion === undefined)) && <span style={{ color: 'red' }}>Campo requerido</span>}
                    </React.Fragment>
                  ))}
                </div>
                <div style={{ textAlign: 'center' }}>
                  <br />
                  <Button variant="contained" color="primary" onClick={handleSubmit}>Enviar respuestas</Button>
                </div>
              </React.Fragment>
            )}
            { pantallaExito && (
              <React.Fragment>
                <div>Muchas gracias!!!</div>
              </React.Fragment>
            )}
            { pantallaError && (
              <React.Fragment>
                <div>Ups, ha ocurrido un problema!!!</div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </section>
    </Box>
  );
}

const LaEncuesta = () => {
  return (
    <PageEncuesta children={<Content />} />
  );
};

export default LaEncuesta;
