import axios, { AxiosResponse } from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";
import { Colegio } from './colegios.service';

export interface Usuario {
  id: string;
  colegio?: Colegio | undefined;
  email: string;
  pass?: string;
  nombres: string;
  apellidos: string;
  rol: string;
  creado?: string;
  activo?: number;
}

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class UsuariosService {
  static async listar() {
    try {
      const response = await axios.get<Usuario[]>(`/usuarios`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async crear(usuario: Usuario) {
    try {
      return await axios.post(`/usuarios`, usuario, { headers: {} });
      // const response = await axios.post(`/usuarios`, usuario, { headers: {} });

      // if (response.status === 201) {
      //   return true;
      // } else {
      //   return false;
      // }
    } catch (e: any) {
      console.error(e);
      return e.response;
      // return false;
    }
  }

  static async eliminar(id: string) {
    try {
      const response = await axios.delete(`/usuarios/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  static async actualizar(usuario: Usuario) {
    try {
      const response = await axios.patch(`/usuarios/${usuario.id}`, usuario, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default UsuariosService;
