import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl } from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import { useRoleCheck } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';
import AsignaturasService, { Asignatura } from '../services/asignaturas.service';

import './asignaturas.page.css';

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Asignaturas</Typography>
];

const Content = () => {
  useRoleCheck(['ADM']);
  
  const [refresh, setRefresh] = useState<number>(0);
  const [openDialogCrearAsignatura, setOpenDialogCrearAsignatura] = useState(false);
  const [openDialogModificarAsignatura, setOpenDialogModificarAsignatura] = useState(false);
  const [asignaturas, setAsignaturas] = useState<Asignatura[]>([]);
  const [asignatura, setAsignatura] = useState<Asignatura | undefined>();
  const [createId, setCreateId] = useState<number | undefined>(0);
  const [nombreAsignatura, setNombreAsignatura] = useState<string>('');
  const [activo, setActivo] = useState<number | undefined>(0);
  const [deleteId, setDeleteId] = useState<number | undefined>(0);
  const [updateId, setUpdateId] = useState<number | undefined>(0);

  const fnOpenDialogCrearAsignatura = () => {
    setOpenDialogCrearAsignatura(true);
  };

  const fnOpenDialogModificarAsignatura = (id: number = 0, nombre: string, activo: number | undefined) => {
    setUpdateId(id);
    setNombreAsignatura(nombre);
    setActivo(activo);
    setOpenDialogModificarAsignatura(true);
  };

  const fnCrearAsignatura = () => {
    setUpdateId(0);
    setDeleteId(0);

    const asignatura: Asignatura = {
      id: createId,
      nombre: nombreAsignatura,
      activo: 1
    };
    
    setAsignatura(asignatura);
    fnCloseAllDialogs();
  };
  
  const fnModificarAsignatura = () => {
    setCreateId(0);
    setDeleteId(0);

    const asignatura: Asignatura = {
      id: updateId,
      nombre: nombreAsignatura,
      activo: activo
    };
    
    setAsignatura(asignatura);
    fnCloseAllDialogs();
  };
  
  const fnCloseAllDialogs = () => {
    setOpenDialogCrearAsignatura(false);
    setOpenDialogModificarAsignatura(false);
  };

  useEffect(() => {
    if (deleteId !== 0) {
      (async () => {
        const response = await AsignaturasService.eliminar(deleteId);
        setDeleteId(0);
        setRefresh((refresh) => refresh + 1);
      })();
    }

    if (typeof asignatura === 'object') {
      if (createId === 0) {
        (async () => {
          const response = await AsignaturasService.crear(asignatura);
          setRefresh((refresh) => refresh + 1);
        })();

        setCreateId(0);
        setNombreAsignatura('');
        setActivo(0);
      }
      
      if (updateId !== 0) {
        (async () => {
          const response = await AsignaturasService.actualizar(asignatura);
          setRefresh((refresh) => refresh + 1);
        })();

        setUpdateId(0);
      }

      setAsignatura(undefined);
    }
  }, [asignatura, deleteId]);

  useEffect(() => {
    (async () => {
      const dataAsignaturas = await AsignaturasService.listar();
      setAsignaturas(dataAsignaturas);
    })();
  }, [refresh]);

  return (
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrearAsignatura} >Crear</Button>
      <Dialog open={openDialogCrearAsignatura} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR ASIGNATURA</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Complete los siguientes datos:
          </DialogContentText><br /> */}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="id"
              label="ID"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setCreateId(Number(e.target.value))}
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nombre de la asignatura"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setNombreAsignatura(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="activo"
              label="Activo"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setActivo(Number(e.target.value))}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrearAsignatura}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogModificarAsignatura} onClose={fnCloseAllDialogs}>
        <DialogTitle>MODIFICAR ASIGNATURA</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nombre de la asignatura"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setNombreAsignatura(e.target.value)}
            value={ nombreAsignatura }
          />
          <TextField
            autoFocus
            margin="dense"
            id="activo"
            label="Activo"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setActivo(Number(e.target.value))}
            value={ activo }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={fnModificarAsignatura}>Guardar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">NOMBRE</TableCell>
                <TableCell align="center">ACTIVA</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {asignaturas.map((asignatura: Asignatura) => (
                <TableRow
                  key={asignatura.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {asignatura.nombre}
                  </TableCell>
                  <TableCell align="center">{asignatura.activo}</TableCell>
                  <TableCell align="right">
                  <Button variant="contained" size="small" startIcon={<SettingsRoundedIcon />} onClick={() => fnOpenDialogModificarAsignatura(asignatura.id, asignatura.nombre!, asignatura.activo)}>Modificar</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={ () => setDeleteId(asignatura.id) }>Eliminar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              {/* <TableRow>
                <TableCell align="center" colSpan={3}>
                  <div className='pagination-container'>
                    <Stack spacing={5}>
                      <Pagination count={10} shape="rounded" page={1} />
                    </Stack>
                  </div>
                </TableCell>
              </TableRow> */}
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

const Asignaturas = () => {
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content />} />
  );
};

export default Asignaturas;
