import axios from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";
import { Formulario, Pregunta } from './formularios.service';
import { Curso } from './cursos.service';
import { Estudiante } from './estudiantes.service';
import { Asignatura } from './asignaturas.service';
import { Nivel } from './niveles.service';

export interface Encuesta {
  id?: string;
  formulario?: Formulario;
  curso?: Curso;
  nivel?: Nivel;
  estudiante?: Estudiante;
  asignatura?: Asignatura;
  link?: string;
  folio?: number;
  expiracion?: string;
  respondida?: number;
  expirada?: number;
  creado?: string;
  actualizado?: string;
  preguntas?: Pregunta[];
  total?: number;
  respondidas?: number;
  pendientes?: number;
};

export interface Respuesta {
  id: number;
  encuesta: Encuesta;
  pregunta: Pregunta;
  calificacion?: number;
  verdadero?: number;
};

export interface PieChartData {
  formulario?: Formulario;
  encuestas?: number;
  encuestados?: number;
};

export interface QuizDataASG {
  indice: number;
  pregunta: string;
  nota: number;
  total: number;
}

export interface QuizDataCAA {
  indice: number;
  pregunta: string;
  si: number;
  no: number;
  total: number;
}

export interface DashboardASG {
  formulario?: string;
  asignatura?: string;
  nivel?: string;
  pregunta?: string;
  promedio?: number;
};

export interface DashboardCAA {
  formulario?: string;
  nivel?: string;
  pregunta?: string;
  si?: number;
  no?: number;
  respuestas?: number;
};

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class EncuestasService {
  static async listar() {
    let encuestas: Encuesta[] = [];

    try {
      const response = await axios.get<Encuesta[]>(`/encuestas`);

      encuestas = response.data;
    } catch (e) {
      console.error(e);
    } finally {
      return encuestas;
    }
  };

  static async crear(encuesta: Encuesta) {
    try {
      const response = await axios.post(`/encuestas`, encuesta, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async eliminar(id: string) {
    try {
      const response = await axios.delete(`/encuestas/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async get(id: string) {
    try {
      const response = await axios.get(`/encuestas/${id}`);

      return response;
    } catch (e) {
      console.error(e)
    }
  }

  static async getPieChart() {
    let charts: PieChartData[] = [];

    try {
      const response = await axios.get<PieChartData[]>(`/encuestas/pie-chart`);
      charts = response.data;
    } catch (e) {
      console.error(e)
    } finally {
      return charts;
    }
  }

  static async getDashboardAdminASG() {
    let dashboards: any[] = [];

    try {
      const response = await axios.get<any[]>(`/encuestas/dashboard-asg-adm`);
      dashboards = response.data;
    } catch (e) {
      console.error(e)
    } finally {
      return dashboards;
    }
  }

  static async getDashboardAdminCAA() {
    let dashboards: any[] = [];

    try {
      const response = await axios.get<any[]>(`/encuestas/dashboard-caa-adm`);
      dashboards = response.data;
    } catch (e) {
      console.error(e)
    } finally {
      return dashboards;
    }
  }

  static async getDashboardCollegeASG() {
    let dashboards: any[] = [];

    try {
      const response = await axios.get<any[]>(`/encuestas/dashboard-asg`);
      dashboards = response.data;
    } catch (e) {
      console.error(e)
    } finally {
      return dashboards;
    }
  }

  static async getDashboardCollegeCAA() {
    let dashboards: any[] = [];

    try {
      const response = await axios.get<any[]>(`/encuestas/dashboard-caa`);
      dashboards = response.data;
    } catch (e) {
      console.error(e)
    } finally {
      return dashboards;
    }
  }

  static async getAdmQuizDataASG(id: string) {
    let quizData: QuizDataASG[] = [];

    try {
      const response = await axios.get<QuizDataASG[]>(`/encuestas/adm-quiz-asg/${id}`);
      quizData = response.data;
    } catch (e) {
      console.error(e)
    } finally {
      return quizData;
    }
  }

  static async getAdmQuizDataCAA(id: string) {
    let quizData: QuizDataCAA[] = [];

    try {
      const response = await axios.get<QuizDataCAA[]>(`/encuestas/adm-quiz-caa/${id}`);
      quizData = response.data;
    } catch (e) {
      console.error(e)
    } finally {
      return quizData;
    }
  }

  static async getQuizDataASG(folio: string) {
    let quizData: QuizDataASG[] = [];

    try {
      const response = await axios.get<QuizDataASG[]>(`/encuestas/quiz-asg/${folio}`);
      quizData = response.data;
    } catch (e) {
      console.error(e)
    } finally {
      return quizData;
    }
  }

  static async getQuizDataCAA(folio: string) {
    let quizData: QuizDataCAA[] = [];

    try {
      const response = await axios.get<QuizDataCAA[]>(`/encuestas/quiz-caa/${folio}`);
      quizData = response.data;
    } catch (e) {
      console.error(e)
    } finally {
      return quizData;
    }
  }

  static async save(encuesta: Encuesta, respuestas: Respuesta[]) {
    try {
      const response = await axios.patch(`/encuestas/${encuesta.id}`, respuestas, { headers: {} });

      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default EncuestasService;
