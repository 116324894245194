import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';

import { useRoleCheck, isASG, isCAA } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';

import EncuestasService, { DashboardASG, DashboardCAA, Encuesta, QuizDataASG, QuizDataCAA } from '../services/encuestas.service';

import './inicioAsgCaa.page.css';

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Encuestas</Typography>
];

const Content = () => {
  useRoleCheck(['ASG', 'CAA']);

  const [encuestas, setEncuestas] = useState<Encuesta[]>([]);
  const [openDialogDetalleEncuesta, setOpenDialogDetalleEncuesta] = useState(false);
  const [formId, setFormId] = useState<number | undefined>();
  const [formType, setFormType] = useState<string | undefined>();
  const [formName, setFormName] = useState<string | undefined>();
  const [folio, setFolio] = useState<string | undefined>();
  const [asignatura, setAsignatura] = useState<string | undefined>();
  const [respuestasASG, setRespuestasASG] = useState<QuizDataASG[]>([]);
  const [respuestasCAA, setRespuestasCAA] = useState<QuizDataCAA[]>([]);
  const [dashboardsASG, setDashboardsASG] = useState<any[]>([]);
  const [dashboardsCAA, setDashboardsCAA] = useState<any[]>([]);

  const fnGetPercentage = (parte: number, total: number) => {
    // Verifica si el denominador (total) es igual a cero para evitar una división por cero.
    if (total === 0) {
      throw new Error('El denominador (total) no puede ser cero.');
    }
  
    // Calcula el porcentaje como (parte / total) * 100.
    const porcentaje = (parte / total) * 100;
  
    // Redondea el resultado a dos decimales (opcional).
    return Math.round(porcentaje * 100) / 100;
  }

  const fnOpenDialogDetalleEncuesta = (folio: number, asignatura: string, id: number, tipo: string, nombre: string) => {
    setFormId(id);
    setFormType(tipo);
    setFormName(nombre);
    setFolio(folio.toString());
    setAsignatura(asignatura);
    setOpenDialogDetalleEncuesta(true);
  };

  const fnCloseAllDialogs = () => {
    setOpenDialogDetalleEncuesta(false);
    setRespuestasASG([]);
    setRespuestasCAA([]);
    setFormId(undefined);
    setFormType(undefined);
    setFormName(undefined);
    setFolio(undefined);
    setAsignatura(undefined);
  };

  const fnOpenDialogPreguntasFormulario = (formularioId: number) => {
    
  };
  
  const fnOpenDialogAlumnosCurso = (cursoId: string) => {
    
  };

  const fnFormatFecha = (fecha: string) => {
    return fecha.substring(0,10);
  };

  const fnFormatNombreCurso = (cursoId: string, nombreNivel: string) => {
    const [temporada, a, b, letra, id] = cursoId.split('_');
    
    return `${nombreNivel} ${letra} (${temporada})`;
  };

  useEffect(() => {
    if (formId !== undefined && formType === 'ASG') {
      (async () => {
        const response = await EncuestasService.getQuizDataASG(folio!.toString());
        setRespuestasASG(response);
      })();

      setFormId(undefined);
    }
    if (formId !== undefined && formType === 'CAA') {
      (async () => {
        const response = await EncuestasService.getQuizDataCAA(folio!.toString());
        setRespuestasCAA(response);
      })();

      setFormId(undefined);
    }
  }, [formId]);

  useEffect(() => {
    (async () => {
      const dataEncuestas = await EncuestasService.listar();
      setEncuestas(dataEncuestas);
      // console.log('ENCUESTAS')
      // console.log(dataEncuestas);
    })();
    
    (async () => {
      const dataDashboardsASG = await EncuestasService.getDashboardCollegeASG();
      setDashboardsASG(dataDashboardsASG);
      // console.log('DASHBOARDS ASG')
      // console.log(dataDashboardsASG);
    })();
    
    (async () => {
      const dataDashboardsCAA = await EncuestasService.getDashboardCollegeCAA();
      setDashboardsCAA(dataDashboardsCAA);
      // console.log('DASHBOARDS CAA')
      // console.log(dataDashboardsCAA);
    })();
  }, []);

  return (
    <section>
      <Dialog open={openDialogDetalleEncuesta} onClose={fnCloseAllDialogs}>
        <DialogTitle align='center'>{formName}</DialogTitle>
        { asignatura !== '' && (<h4 className="centrarTexto">{asignatura}</h4>) }
        <DialogContent>
          <br/>
          <TableContainer component={Paper}>
            { formType === 'ASG' && (
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nº</TableCell>
                    <TableCell align="left">PREGUNTA</TableCell>
                    <TableCell align="center">NOTA</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {respuestasASG.map((respuesta: QuizDataASG, index) => (
                    <TableRow
                      key={`pregunta.${respuesta.indice}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left">{respuesta.pregunta}</TableCell>
                      <TableCell align="center">{respuesta.nota}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            { formType === 'CAA' && (
              <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Nº</TableCell>
                    <TableCell align="left">PREGUNTA</TableCell>
                    <TableCell align="center">SÍ</TableCell>
                    <TableCell align="center">NO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {respuestasCAA.map((respuesta: QuizDataCAA, index) => (
                    <TableRow
                      key={`pregunta.${respuesta.indice}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="left">{respuesta.pregunta}</TableCell>
                      <TableCell align="center">{fnGetPercentage(respuesta.si, respuesta.total)}%</TableCell>
                      <TableCell align="center">{fnGetPercentage(respuesta.no, respuesta.total)}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCloseAllDialogs}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <div className="container">
        { isASG() && dashboardsASG.length > 0 &&
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">FORMULARIO</TableCell>
                    <TableCell align="center">ASIGNATURA</TableCell>
                    <TableCell align="center">NIVEL</TableCell>
                    <TableCell align="center">PREGUNTA</TableCell>
                    <TableCell align="center">PROMEDIO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboardsASG.map((dataArray: DashboardASG[], arrayIndex) => (
                    dataArray.map((data: DashboardASG, index) => (
                      <TableRow
                        key={`${arrayIndex}-${index}-asg`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" component="th" scope="row">
                          {data.formulario}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {data.asignatura}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {data.nivel}
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                          {data.pregunta}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {data.promedio}
                        </TableCell>
                      </TableRow>
                    ))
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
          </>
        }
        { isCAA() && dashboardsCAA.length > 0 &&
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">FORMULARIO</TableCell>
                    <TableCell align="center">NIVEL</TableCell>
                    <TableCell align="center">PREGUNTA</TableCell>
                    <TableCell align="center">SÍ</TableCell>
                    <TableCell align="center">NO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboardsCAA.map((dataArray: DashboardCAA[], arrayIndex) => (
                    dataArray.map((data: DashboardCAA, index) => (
                      <TableRow
                        key={`${arrayIndex}-${index}-caa`}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="left" component="th" scope="row">
                          {data.formulario}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {data.nivel}
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                          {data.pregunta}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {`${data.si}%`}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                        {`${data.no}%`}
                        </TableCell>
                      </TableRow>
                    ))
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
          </>
        }
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">FORMULARIO</TableCell>
                <TableCell align="center">CURSO</TableCell>
                <TableCell align="center">ASIGNATURA</TableCell>
                <TableCell align="center">ESTADO</TableCell>
                <TableCell align="center">CREACIÓN</TableCell>
                <TableCell align="center">EXPIRACIÓN</TableCell>
                <TableCell align="center">TOTAL</TableCell>
                <TableCell align="center">RESPONDIDAS</TableCell>
                <TableCell align="center">PENDIENTES</TableCell>
                <TableCell align="center"></TableCell>
                {/* <TableCell align="right"></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {encuestas.map((encuesta) => (
                ((isASG() && encuesta.formulario!.tipo! == 'ASG') || (isCAA() && encuesta.formulario!.tipo! == 'CAA')) && <TableRow
                  key={encuesta.folio}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">{encuesta.formulario!.nombre}</TableCell>
                  <TableCell align="center">{fnFormatNombreCurso(encuesta.curso!.id!, encuesta.nivel!.nombre!)}</TableCell>
                  {encuesta.asignatura!.nombre !== null && <TableCell align="center">{encuesta.asignatura!.nombre}</TableCell>}
                  {encuesta.asignatura!.nombre === null && <TableCell align="center">N/A</TableCell>}
                  {encuesta.expirada === 1 && <TableCell align="center">EXPIRADA</TableCell>}
                  {encuesta.expirada === 0 && <TableCell align="center">VIGENTE</TableCell>}
                  <TableCell align="center">{fnFormatFecha(encuesta.creado!)}</TableCell>
                  <TableCell align="center">{fnFormatFecha(encuesta.expiracion!)}</TableCell>
                  <TableCell align="center">{encuesta.total}</TableCell>
                  <TableCell align="center">{encuesta.respondidas}</TableCell>
                  <TableCell align="center">{encuesta.pendientes}</TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <Button variant="contained" size="small" startIcon={<PreviewRoundedIcon />} onClick={() => fnOpenDialogDetalleEncuesta(encuesta.folio!, (encuesta.asignatura!.nombre !== null ? encuesta.asignatura!.nombre! : 'CAA'), encuesta.formulario!.id!, encuesta.formulario!.tipo!, fnFormatNombreCurso(encuesta.curso!.id!, encuesta.nivel!.nombre!))}>Ver Detalle</Button>
                  </TableCell>
                  {/* <TableCell align="right">
                    <Button variant="contained" size="small" startIcon={<PreviewRoundedIcon />} onClick={() => fnOpenDialogPreguntasFormulario(encuesta.formulario!.id!)}>Preguntas</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<PreviewRoundedIcon />} onClick={() => fnOpenDialogAlumnosCurso(encuesta.curso!.id!)}>Alumnos</Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              {/* <TableRow>
                <TableCell align="center" colSpan={3}>
                  <div className='pagination-container'>
                    <Stack spacing={5}>
                      <Pagination count={10} shape="rounded" page={1} />
                    </Stack>
                  </div>
                </TableCell>
              </TableRow> */}
            </TableFooter>
          </Table>
        </TableContainer>
        <br />
      </div>
    </section>
  );
};

const InicioAsgCaa = () => {
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content />} />
  );
};

export default InicioAsgCaa;
