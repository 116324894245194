import axios from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";
import { Colegio } from './colegios.service';

export interface Formulario {
  id: number | undefined;
  colegio?: Colegio | undefined;
  tipo?: string | undefined;
  nombre?: string | undefined;
  preguntas?: Pregunta[] | undefined;
  creado?: string;
  activo?: number;
  encuestas?: number;
};

export interface Pregunta {
  id: number;
  formulario?: Formulario;
  indice?: number;
  pregunta?: string;
  creado?: string;
  activo?: number;
};

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class FormulariosService {
  static async listar() {
    try {
      const response = await axios.get<Formulario[]>(`/formularios`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async preguntas(id: number) {
    try {
      const response = await axios.get<Pregunta[]>(`/formularios/${id}/preguntas`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async crear(formulario: Formulario) {
    try {
      const response = await axios.post(`/formularios`, formulario, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async eliminar(id: number) {
    try {
      const response = await axios.delete(`/formularios/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async actualizar(formulario: Formulario) {
    try {
      // console.log(formulario);
      const response = await axios.patch(`/formularios/${formulario.id}`, formulario, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default FormulariosService;
