import { isADM } from '../hooks/use-role-check.hook';
import InicioAdm from "../pages/inicioAdm.page";
import InicioAsgCaa from "../pages/inicioAsgCaa.page";

import './inicioAdm.page.css';

const Inicio = () => {
  if (isADM()) {
    return( <InicioAdm /> );
  } else {
    return( <InicioAsgCaa /> );
  }
};

export default Inicio;