import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl, InputLabel, Input } from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import CloudDownloaRoundeddIcon from '@mui/icons-material/CloudDownloadRounded';

import { useRoleCheck, isADM } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';
import EstudiantesService, { Estudiante } from '../services/estudiantes.service';
import { formatRutForDisplay, formatRutForInput, rutValido, insertDash } from '../helpers/validations-form.helper';

import './estudiantes.page.css';

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Estudiantes</Typography>
];

const Content = () => {
  useRoleCheck(['ADM', 'ASG', 'CAA']);

  const [refresh, setRefresh] = useState<number>(0);
  // const [download, setDownload] = useState<number>(0);
  const [openDialogCrearEstudiante, setOpenDialogCrearEstudiante] = useState(false);
  const [openDialogCargarEstudiantes, setOpenDialogCargarEstudiantes] = useState(false);
  const [openDialogModificarEstudiante, setOpenDialogModificarEstudiante] = useState(false);
  const [estudiantes, setEstudiantes] = useState<Estudiante[]>([]);
  const [estudiante, setEstudiante] = useState<Estudiante | undefined>();
  const [deleteId, setDeleteId] = useState<string>('');
  const [updateId, setUpdateId] = useState<string>('');
  const [createId, setCreateId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [nombres, setNombres] = useState<string>('');
  const [apellidos, setApellidos] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadTrigger, setUploadTrigger] = useState(0);

  const fnOpenDialogCrearEstudiante = () => {
    setOpenDialogCrearEstudiante(true);
  };
  
  const fnOpenDialogCargarEstudiantes = () => {
    setOpenDialogCargarEstudiantes(true);
  };

  const fnOpenDialogModificarEstudiante = (id: string, email:string, nombres: string, apellidos: string) => {
    setUpdateId(id);
    setEmail(email);
    setNombres(nombres);
    setApellidos(apellidos);
    setOpenDialogModificarEstudiante(true);
  };

  const fnCrearEstudiante = () => {
    setUpdateId('');
    setDeleteId('');

    if (rutValido(createId)) {
      const estudiante: Estudiante = {
        id: insertDash(createId),
        email: email,
        nombres: nombres,
        apellidos: apellidos
      };
      
      setEstudiante(estudiante);
      fnCloseAllDialogs();
    } else {
      // TODO: Implementar un mensaje de error.
    }
  };

  const fnCargarArchivoCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files ? event.target.files[0] : null);
  }

  const fnCargarEstudiantes = () => {
    setUploadTrigger((prev) => prev + 1);
  };

  // const fnCargarEstudiantes = async (event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.preventDefault();

  //   const data = new FormData();

  //   if (selectedFile) {
  //     data.append('file', selectedFile);
  //   }

  //   const response = await fetch('http://tu-api-endpoint.com/upload', {
  //     method: 'POST',
  //     body: data
  //   });

  //   // maneja la respuesta del servidor
  // };
  
  const fnModificarEstudiante = () => {
    setCreateId('');
    setDeleteId('');

    const estudiante: Estudiante = {
      id: updateId,
      email: email,
      nombres: nombres,
      apellidos: apellidos
    };
    
    setEstudiante(estudiante);
    fnCloseAllDialogs();
  };

  const fnDescargarArchivoBase = async () => {
    // setDownload((download) => download + 1);
    try {
      const response = await EstudiantesService.descargar();

      // Crear una URL temporal para el archivo descargado
      const blob = new Blob([response!.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      // Crear un enlace y simular el clic para iniciar la descarga
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'plantilla-de-carga.csv'); // Cambia el nombre del archivo según tu necesidad
      document.body.appendChild(link);
      link.click();

      // Liberar recursos
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  };
  
  const fnCloseAllDialogs = () => {
    setOpenDialogCrearEstudiante(false);
    setOpenDialogCargarEstudiantes(false);
    setOpenDialogModificarEstudiante(false);
  };

  useEffect(() => {
    if (deleteId !== '') {
      (async () => {
        const response = await EstudiantesService.eliminar(deleteId);
        setDeleteId('');
        setRefresh((refresh) => refresh + 1);
      })();
    }

    if (typeof estudiante === 'object') {
      if (createId !== '') {
        (async () => {
          const response = await EstudiantesService.crear(estudiante);
          setRefresh((refresh) => refresh + 1);
        })();

        setCreateId('');
        setNombres('');
        setApellidos('');
      }
      
      if (updateId !== '') {
        (async () => {
          const response = await EstudiantesService.actualizar(estudiante);
          setRefresh((refresh) => refresh + 1);
        })();

        setUpdateId('');
      }

      setEstudiante(undefined);
    }
  }, [estudiante, deleteId]);

  useEffect(() => {
    if (selectedFile) {
      (async () => {
        await EstudiantesService.cargar(selectedFile);
        setRefresh((refresh) => refresh + 1);
        setOpenDialogCargarEstudiantes(false);
      })();
    }
  }, [uploadTrigger]);

  // useEffect(() => {
  //   (async () => {
  //     await EstudiantesService.descargar();
  //   })();
  // }, [download]);
  
  useEffect(() => {
    (async () => {
      const dataEstudiantes = await EstudiantesService.listar();
      setEstudiantes(dataEstudiantes);
    })();
  }, [refresh]);

  return (
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrearEstudiante} disabled={isADM()}>Crear</Button>&nbsp;
      <Button variant="contained" size="small" startIcon={<CloudUploadRoundedIcon />} onClick={fnOpenDialogCargarEstudiantes} disabled={isADM()}>Cargar</Button>&nbsp;
      <Button variant="contained" size="small" startIcon={<CloudDownloaRoundeddIcon />} onClick={fnDescargarArchivoBase}>Plantilla de Carga</Button>
      <Dialog open={openDialogCrearEstudiante} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR ESTUDIANTE</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Complete los siguientes datos:
          </DialogContentText><br /> */}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="id"
              label="RUT"
              type="text"
              fullWidth
              variant="standard"
              value={formatRutForDisplay(createId)}
              onChange={(e) => setCreateId(formatRutForInput(e.target.value))}
              inputProps={{ maxLength: 12 }}
            />
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="email"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="nombres"
              label="Nombres"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setNombres(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="apellidos"
              label="Apellidos"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setApellidos(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrearEstudiante}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogCargarEstudiantes} onClose={fnCloseAllDialogs}>
        <DialogTitle>CARGAR ESTUDIANTES</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <InputLabel htmlFor="file">Upload file</InputLabel>
            <Input type="file" id="file" onChange={fnCargarArchivoCSV} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCargarEstudiantes}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogModificarEstudiante} onClose={fnCloseAllDialogs}>
        <DialogTitle>MODIFICAR ESTUDIANTE</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="email"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setEmail(e.target.value)}
            value={ email }
          />
          <TextField
            autoFocus
            margin="dense"
            id="nombres"
            label="Nombres"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setNombres(e.target.value)}
            value={ nombres }
          />
          <TextField
            autoFocus
            margin="dense"
            id="apellidos"
            label="Apellidos"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setApellidos(e.target.value)}
            value={ apellidos }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={fnModificarEstudiante}>Guardar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">RUT</TableCell>
                <TableCell align="left">NOMBRES</TableCell>
                <TableCell align="center">APELLIDOS</TableCell>
                <TableCell align="center">EMAIL</TableCell>
                { isADM() && <TableCell align="center">COLEGIO</TableCell> }
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estudiantes.map((estudiante: Estudiante) => {
                const cursoId = estudiante.curso?.id;
                let formattedCursoId = cursoId || ''; // Usamos el valor original por defecto
            
                if (cursoId) {
                  const parts = cursoId.split("_");
                  // Comprobamos que hay al menos 5 partes
                  if (parts.length >= 5) {
                    formattedCursoId = `${parts[1]} ${parts[2]} ${parts[3]} (${parts[0]})`;
                  }
                  // TODO: Una vez que se eliminen los cursos actuales y se creen nuevos, habilitar este código y eliminar el que está arriba.
                  // if (parts.length >= 5) {
                  //   formattedCursoId = `${parts[1]} ${parts[2]} ${parts[3]} (${parts[0]})`;
                  // }
                }

                return (
                  <TableRow
                    key={estudiante.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">{estudiante.id}</TableCell>
                    <TableCell component="th" scope="row">
                      {estudiante.nombres}
                    </TableCell>
                    <TableCell align="center">{estudiante.apellidos}</TableCell>
                    <TableCell align="center">{estudiante.email}</TableCell>
                    { isADM() && <TableCell align="center">{estudiante.colegio!.nombre}</TableCell> }
                    <TableCell align="right">
                    <Button variant="contained" size="small" startIcon={<SettingsRoundedIcon />} onClick={() => fnOpenDialogModificarEstudiante(estudiante.id, estudiante.email!, estudiante.nombres, estudiante.apellidos)}>Modificar</Button>&nbsp;
                      <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={ () => setDeleteId(estudiante.id) } disabled={estudiante.btnDelete! === 0}>Eliminar</Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              {/* <TableRow>
                <TableCell align="center" colSpan={5}>
                  <div className='pagination-container'>
                    <Stack spacing={5}>
                      <Pagination count={10} shape="rounded" page={1} />
                    </Stack>
                  </div>
                </TableCell>
              </TableRow> */}
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

const Estudiantes = () => {
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content />} />
  );
};

export default Estudiantes;
