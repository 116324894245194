import React, { useEffect, useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AuthService, { Credential } from '../services/auth.service';
import { formatRutForDisplay, formatRutForInput, rutValido, insertDash } from '../helpers/validations-form.helper';

import './login.page.css';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();

  const fnLogin = async (event: React.FormEvent) => {
    // Prevenir el comportamiento por defecto del formulario
    event.preventDefault();

    if (username !== '' && password !== '' && rutValido(username)) {
      const credential: Credential = {
        user: insertDash(username),
        pass: password
      };

      if (await AuthService.logIn(credential)) {
        navigate('/');
      }
    } else {
      // TODO: Implementar un mensaje de error.
    }
  };

  useEffect(() => {
    (() => {
      setUsername('');
      setPassword('');
      AuthService.logOut();
    })();
  }, []);

  return (
    <Box>
      <form onSubmit={fnLogin} className="login-container">
        <img src="logo.png" />
        <h2>Plataforma de Consultas Estudiantiles</h2>
        <TextField
          label="Ingrese RUT"
          value={formatRutForDisplay(username)}
          onChange={(e) => setUsername(formatRutForInput(e.target.value))}
          inputProps={{ maxLength: 12 }}
        />
        <br />
        <TextField
          label="Ingrese Contraseña"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="off"
        />
        <br />
        <Button variant="contained" type="submit">Ingresar</Button>
      </form>
    </Box>
  );
};

export default Login;
