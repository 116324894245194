/****************************************/
// VALIDACIONES DE RUT
/****************************************/

// Valida el rut con su cadena completa "XXXXXXXX-X"
export const rutValido = (rutCompleto: string) => {
  // console.log(rutCompleto);
  const rutFormatted = rutFormatter(rutCompleto)
  // console.log(rutFormatted);

  if (!/^[0-9]+[-]{1}[0-9K]{1}$/.test(rutFormatted)) {
    return false;
  }

  let tmp: string[] = rutFormatted.split('-');
  let dv: string = tmp[1].toUpperCase();
  let rut: string = tmp[0];

  return (String(validaDV(Number(rut))) === dv);
};

const validaDV = (T: number) => {
  let M = 0;
  let S = 1;

  for (; T; T = Math.floor(T / 10)) {
    S = (S + T % 10 * (9 - M++ % 6)) % 11;
  }

  return S ? S - 1 : 'K';
}

export const rutFormatter = (rut: string) => {
  rut = rut.replace(/[^\dkK]+/g, ''); // Solo mantiene dígitos y k/K
  rut = rut.toUpperCase();
  rut = insertDash(rut);

  return rut;
};

export const formatRutForInput = (rut: string) => {
  rut = rut.replace(/[^\dkK]+/g, ''); // Solo mantiene dígitos y k/K
  rut = rut.toUpperCase();

  return rut;
};

export const formatRutForDisplay = (rut: string) => {
  rut = rut.replace(/[^\dkK]+/g, ''); // Solo mantiene dígitos y k/K
  rut = rut.toUpperCase();

  if (rut.length <= 1) {
    return rut;
  }

  const lastChar = rut.charAt(rut.length - 1);
  let numericPart = rut.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return `${numericPart}-${lastChar}`;
};

export const insertDash = (rut: string) => {
  const penultimateIndex = rut.length - 1;
  return rut.slice(0, penultimateIndex) + '-' + rut.slice(penultimateIndex);
};

/****************************************/
// VALIDACIONES DE EMAIL
/****************************************/

export const emailValido = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email); // true or false
};

export const formatEmailForInput = (email: string) => {
  return email.toUpperCase();
};

export const formatEmailForDisplay = (email: string) => {
  return email.toUpperCase();
};
