import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import SendIcon from '@mui/icons-material/Send';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { useRoleCheck, isADM, isASG, isCAA } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';
import FormulariosService, { Formulario, Pregunta } from '../services/formularios.service';
import EncuestasService, { Encuesta } from '../services/encuestas.service';
import { Colegio } from '../services/colegios.service';
import AsignaturasService, { Asignatura } from "../services/asignaturas.service";
import CursosService, { Curso } from "../services/cursos.service";

import './formularios.page.css';

import dayjs from 'dayjs';
import "dayjs/locale/es";
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/de';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Formularios</Typography>
];

const Content = () => {
  useRoleCheck(['ADM', 'ASG', 'CAA']);

  const locales = ['es', 'en', 'en-gb', 'zh-cn', 'de'];
  const locale = locales[0];

  const [refresh, setRefresh] = useState<number>(0);
  const [refreshEncuesta, setRefreshEncuesta] = useState<number>(0);
  const [openDialogCrearFormulario, setOpenDialogCrearFormulario] = useState(false);
  const [openDialogRealizarEncuesta, setOpenDialogRealizarEncuesta] = useState(false);
  const [openDialogModificarFormulario, setOpenDialogModificarFormulario] = useState(false);
  const [openDialogPreguntasFormulario, setOpenDialogPreguntasFormulario] = useState(false);
  const [formularios, setFormularios] = useState<Formulario[]>([]);
  const [asignaturas, setAsignaturas] = useState<Asignatura[]>([]);
  const [cursos, setCursos] = useState<Curso[]>([]);
  const [formulario, setFormulario] = useState<Formulario | undefined>();
  const [encuestaFormularioId, setEncuestaFormularioId] = useState<number | undefined>();
  const [encuestaCursoId, setEncuestaCursoId] = useState<number | undefined>();
  const [encuestaFechaExpiracion, setEncuestaFechaExpiracion] = useState<Date | null>(null);
  const [formId, setFormId] = useState<number | undefined>();
  const [asignaturaId, setAsignaturaId] = useState<number | undefined>();
  const [cursoId, setCursoId] = useState<string | undefined>();
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [updateId, setUpdateId] = useState<number | undefined>();
  const [createId, setCreateId] = useState<number | undefined>();
  const [colegio, setColegio] = useState<Colegio | undefined>();
  const [tipo, setTipo] = useState<string | undefined>();
  const [nombre, setNombre] = useState<string | undefined>();
  const [preguntas, setPreguntas] = useState<Pregunta[]>([]);
  const [preguntasModificadas, setPreguntasModificadas] = useState<Pregunta[]>([]);
  const [encuesta, setEncuesta] = useState<Encuesta | undefined>();
  const [id, setId] = useState<number>(0);

  const fnOpenDialogCrearFormulario = () => {
    setOpenDialogCrearFormulario(true);
  };
  
  const fnOpenDialogRealizarEncuesta = (id: number, tipo: string) => {
    setUpdateId(id);
    setTipo(tipo);
    setOpenDialogRealizarEncuesta(true);
    setRefreshEncuesta((refreshEncuesta) => refreshEncuesta + 1);
  };

  const fnOpenDialogModificarFormulario = (id: number | undefined, tipo: string, nombre: string) => {
    setFormId(id);
    setUpdateId(id);
    setTipo(tipo);
    setNombre(nombre);
    setOpenDialogModificarFormulario(true);
  };

  const fnOpenDialogPreguntasFormulario = (id: number | undefined) => {
    setFormId(id);
    setOpenDialogPreguntasFormulario(true);
  };

  const fnCrearFormulario = () => {
    setUpdateId(undefined);
    setDeleteId(undefined);

    const formulario: Formulario = {
      id: createId,
      colegio: colegio,
      tipo: tipo,
      nombre: nombre,
      preguntas: preguntasModificadas
    };
    
    if (formulario.nombre !== undefined && formulario.nombre !== '' && formulario.tipo != undefined) {
      setCreateId(0);
      setFormulario(formulario);
      fnCloseAllDialogs();
    }
  };

  const fnEnviarEncuesta = () => {
    const date = new Date(encuestaFechaExpiracion!);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const encuesta: Encuesta = {
      formulario: {
        id: updateId
      },
      curso: {
        id: cursoId
      },
      asignatura: {
        id: asignaturaId
      },
      expiracion: formattedDate
    };
    
    setEncuesta(encuesta);
    // console.log(encuesta);
    fnCloseAllDialogs();
  };
  
  const fnModificarFormulario = () => {
    setCreateId(undefined);
    setDeleteId(undefined);

    const formulario: Formulario = {
      id: updateId,
      colegio: colegio,
      tipo: tipo,
      nombre: nombre,
      preguntas: preguntasModificadas
    };
    
    if (formulario.nombre !== undefined && formulario.nombre !== '' && formulario.tipo != undefined) {
      setFormulario(formulario);
      fnCloseAllDialogs();
    }
  };

  const handlePreguntaChange = (index: number, newText: string) => {
    const updatedPreguntas = preguntasModificadas.map((pregunta, i) =>
      i === index ? { ...pregunta, pregunta: newText } : pregunta
    );

    setPreguntasModificadas(updatedPreguntas);
  };

  const handleEncuestaFechaExpiracion = (date: Date | null) => {
    setEncuestaFechaExpiracion(date);
  };

  const agregarPregunta = () => {
    setId(id + 1);

    const nuevaPregunta: Pregunta = {
      id: id,
      formulario: {
        id: updateId
      },
      indice: preguntasModificadas.length + 1,
      pregunta: '',
    };

    setPreguntasModificadas([...preguntasModificadas, nuevaPregunta]);
  };

  const eliminarPregunta = (id: number) => {
    const nuevasPreguntas = preguntasModificadas.filter((pregunta) => pregunta.id !== id);
  
    setPreguntasModificadas(nuevasPreguntas);
    reordenarIndices(nuevasPreguntas);
  };

  const reordenarIndices = (preguntas: Pregunta[]) => {
    const preguntasConNuevosIndices = preguntas.map((pregunta, index) => ({
      ...pregunta,
      indice: index + 1
    }));

    setPreguntasModificadas(preguntasConNuevosIndices);
  };

  const cambiarPosicion = (id: number, operation: string) => {
    const pregunta = preguntasModificadas.find((pregunta) => pregunta.id === id);
  
    if (pregunta) {
      const nuevaLista = preguntasModificadas.filter((preg) => preg.id !== id);

      if (operation === 'up') {
        nuevaLista.splice(pregunta.indice! - 2, 0, pregunta);
      }

      if (operation === 'down') {
        nuevaLista.splice(pregunta.indice!, 0, pregunta);
      }
      
      reordenarIndices(nuevaLista);
    }
  };
  
  const fnCloseAllDialogs = () => {
    setOpenDialogCrearFormulario(false);
    setOpenDialogRealizarEncuesta(false);
    setOpenDialogModificarFormulario(false);
    setOpenDialogPreguntasFormulario(false);
    setTipo(undefined);
    setAsignaturaId(undefined);
    setCursoId(undefined);
    setEncuestaFechaExpiracion(null);
  };

  useEffect(() => {
    if (deleteId !== undefined) {
      (async () => {
        const response = await FormulariosService.eliminar(deleteId);
        setDeleteId(undefined);
        setRefresh((refresh) => refresh + 1);
      })();
    }

    if (formId !== undefined) {
      (async () => {
        const response = await FormulariosService.preguntas(formId);
        let counter = 1;
        response.forEach((pregunta: Pregunta) => {
          pregunta.id = counter;
          counter++;
        });
        setId(counter);
        setPreguntas(response);
        setPreguntasModificadas(response);
      })();

      setFormId(undefined);
    }

    if (typeof formulario === 'object') {
      if (createId !== undefined) {
        (async () => {
          const response = await FormulariosService.crear(formulario);
          setRefresh((refresh) => refresh + 1);
        })();

        setCreateId(undefined);
        setTipo('');
        setNombre('');
      }
      
      if (updateId !== undefined) {
        (async () => {
          const response = await FormulariosService.actualizar(formulario);
          setRefresh((refresh) => refresh + 1);
        })();

        setUpdateId(undefined);
      }

      setFormulario(undefined);
    }
  }, [formulario, deleteId, formId]);

  useEffect(() => {
    if (encuesta) {
      (async () => {
        await EncuestasService.crear(encuesta);
        setEncuesta(undefined);
        setUpdateId(undefined);
      })();
    }
  }, [encuesta]);
  
  useEffect(() => {
    (async () => {
      const dataAsignaturas = await AsignaturasService.listar();
      setAsignaturas(dataAsignaturas);
      // console.log('ASIGNATURAS')
      // console.log(dataAsignaturas);
      const dataCursos = await CursosService.listar();
      setCursos(dataCursos);
      // console.log('CURSOS')
      // console.log(dataCursos);
    })();
  }, []);
  
  useEffect(() => {
    (async () => {
      const dataFormularios = await FormulariosService.listar();
      setFormularios(dataFormularios);
      console.log('FORMULARIOS')
      console.log(dataFormularios);
    })();
  }, [refresh]);

  return (
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrearFormulario} >Crear</Button>
      <Dialog open={openDialogCrearFormulario} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR FORMULARIO</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Complete los siguientes datos:
          </DialogContentText><br /> */}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <FormControl variant="standard">
              <InputLabel id="nivel-label">Tipo</InputLabel>
              <Select
                labelId="tipo-label"
                id="tipo"
                value={tipo}
                label="Tipo"
                onChange={e => setTipo(e.target.value)}
              >
                <MenuItem key='asg' value='ASG'>ASIGNATURA</MenuItem>
                <MenuItem key='caa' value='CAA'>CAA</MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              id="nombre"
              label="Nombre"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setNombre(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrearFormulario}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogRealizarEncuesta} onClose={fnCloseAllDialogs}>
        <DialogTitle>REALIZAR ENCUESTA</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            { tipo === 'ASG' ?
              <FormControl variant="standard">
                <InputLabel id="asignatura-label">Asignatura</InputLabel>
                <Select
                  labelId="asignatura-label"
                  id="asignatura"
                  value={asignaturaId}
                  label="Asignatura"
                  onChange={e => setAsignaturaId(Number(e.target.value))}
                >
                  {asignaturas.map((asignatura) => (
                    <MenuItem key={asignatura.id} value={asignatura.id}>{asignatura.nombre}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            : null }
            <FormControl variant="standard">
              <InputLabel id="curso-label">Curso</InputLabel>
              <Select
                labelId="curso-label"
                id="curso"
                value={cursoId}
                label="Curso"
                onChange={e => setCursoId(String(e.target.value))}
              >
                {cursos.map((curso) => (
                  <MenuItem key={curso.id} value={curso.id}>{curso.nivel?.nombre} {curso.letra} ({curso.temporada})</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard">
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Fecha de Expiración"
                    value={encuestaFechaExpiracion}
                    onChange={e => handleEncuestaFechaExpiracion(e)}
                    format="YYYY/MM/DD"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnEnviarEncuesta}>Enviar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogModificarFormulario} onClose={fnCloseAllDialogs} maxWidth="lg">
        <DialogTitle>MODIFICAR FORMULARIO</DialogTitle>
        <DialogContent>
          <FormControl variant="standard">
            <InputLabel id="nivel-label">Tipo</InputLabel>
            <Select
              labelId="tipo-label"
              id="tipo"
              value={tipo}
              label="Tipo"
              onChange={e => setTipo(e.target.value)}
            >
              <MenuItem key='asg' value='ASG'>ASIGNATURA</MenuItem>
              <MenuItem key='caa' value='CAA'>CAA</MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="nombre"
            label="Nombre"
            type="text"
            fullWidth
            variant="standard"
            onChange={e => setNombre(e.target.value)}
            value={ nombre }
          />
          <br /><br />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={() => agregarPregunta()}>Agregar Pregunta</Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {preguntasModificadas.map((pregunta: Pregunta, index) => (
                  <TableRow
                    key={`${pregunta.id}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">
                      <TextField
                        value={pregunta.pregunta}
                        onChange={(e) => handlePreguntaChange(index, e.target.value)}
                        label={`Pregunta ${index+1}`}
                        variant="outlined"
                        sx={{ width: '90ch' }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained" size="small" onClick={() => cambiarPosicion(pregunta.id!, 'up')} disabled={index === 0}><ArrowUpwardIcon /></Button>&nbsp;
                      <Button variant="contained" size="small" onClick={() => cambiarPosicion(pregunta.id!, 'down')} disabled={index === preguntasModificadas.length - 1}><ArrowDownwardIcon /></Button>&nbsp;
                      <Button variant="contained" size="small" onClick={() => eliminarPregunta(pregunta.id!)}><DeleteForeverRoundedIcon /></Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnModificarFormulario}>Guardar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogPreguntasFormulario} onClose={fnCloseAllDialogs}>
        <DialogTitle align='center'>PREGUNTAS DEL FORMULARIO</DialogTitle>
        <DialogContent>
          <br/>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Nº</TableCell>
                  <TableCell align="left">PREGUNTA</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {preguntas.map((pregunta: Pregunta, index) => (
                  <TableRow
                    key={`pregunta.${pregunta.id}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="left">{pregunta.pregunta}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCloseAllDialogs}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">TIPO</TableCell>
                <TableCell align="center">NOMBRE</TableCell>
                <TableCell align="center">CREADOR</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {formularios.map((formulario: Formulario) => (
                (isADM() || (isASG() && formulario.tipo === 'ASG') || (isCAA() && formulario.tipo === 'CAA')) && <TableRow
                  key={formulario.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {formulario.tipo === 'ASG' ? (
                    <TableCell align="center" component="th" scope="row">ASIGNATURA</TableCell>
                  ) : (
                    <TableCell align="center" component="th" scope="row">{formulario.tipo}</TableCell>
                  )}
                  <TableCell align="left">{formulario.nombre}</TableCell>
                  { formulario.colegio!.id !== null &&
                    <TableCell align="center">{formulario.colegio?.nombre}</TableCell>
                  }
                  { formulario.colegio!.id === null &&
                    <TableCell align="center">ADMIN</TableCell>
                  }
                  <TableCell align="right">
                    <Button variant="contained" size="small" startIcon={<SendIcon />} onClick={() => fnOpenDialogRealizarEncuesta(formulario.id!, formulario.tipo!)} disabled={sessionStorage.getItem('role') === 'ADM'}>Realizar Encuesta</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<PreviewRoundedIcon />} onClick={() => fnOpenDialogPreguntasFormulario(formulario.id)}>Ver Preguntas</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<SettingsRoundedIcon />} onClick={() => fnOpenDialogModificarFormulario(formulario.id, formulario.tipo!, formulario.nombre!)} disabled={(formulario.encuestas! > 0 || (formulario.colegio!.id === null && sessionStorage.getItem('role') !== 'ADM'))}>Modificar</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={() => setDeleteId(formulario.id)} disabled={(formulario.encuestas! > 0 || (formulario.colegio!.id === null && sessionStorage.getItem('role') !== 'ADM'))}>Eliminar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              {/* <TableRow>
                <TableCell align="center" colSpan={3}>
                  <div className='pagination-container'>
                    <Stack spacing={5}>
                      <Pagination count={10} shape="rounded" page={1} />
                    </Stack>
                  </div>
                </TableCell>
              </TableRow> */}
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

const Formularios = () => {
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content />} />
  );
};

export default Formularios;
