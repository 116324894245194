import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

import { useRoleCheck } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';

import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';

import './inicioAdm.page.css';
import { useEffect, useState } from "react";
import EncuestasService, { QuizDataASG, QuizDataCAA, PieChartData, DashboardASG, DashboardCAA } from "../services/encuestas.service";
import { calcularPorcentaje } from '../helpers/math.helper';

const breadcrumbs:any = [];
const pieParams = { height: 200, margin: { right: 5 } };

const size = {
  width: 400,
  height: 200,
};

const Content = () => {
  useRoleCheck(['ADM']);
  const [piChartData, setPieChartData] = useState<any[]>([]);
  const [chartDataEncuestados, setChartDataEncuestados] = useState<any[]>([]);
  const [chartDataEncuestas, setChartDataEncuestas] = useState<any[]>([]);
  const [openDialogDetalleEncuesta, setOpenDialogDetalleEncuesta] = useState(false);
  const [formId, setFormId] = useState<number | undefined>();
  const [formType, setFormType] = useState<string | undefined>();
  const [formName, setFormName] = useState<string | undefined>();
  const [respuestasASG, setRespuestasASG] = useState<QuizDataASG[]>([]);
  const [respuestasCAA, setRespuestasCAA] = useState<QuizDataCAA[]>([]);
  const [dashboardsASG, setDashboardsASG] = useState<any[]>([]);
  const [dashboardsCAA, setDashboardsCAA] = useState<any[]>([]);
  
  const fnGetPercentage = (parte: number, total: number) => {
    // Verifica si el denominador (total) es igual a cero para evitar una división por cero.
    if (total === 0) {
      throw new Error('El denominador (total) no puede ser cero.');
    }
  
    // Calcula el porcentaje como (parte / total) * 100.
    const porcentaje = (parte / total) * 100;
  
    // Redondea el resultado a dos decimales (opcional).
    return Math.round(porcentaje * 100) / 100;
  }

  const fnOpenDialogDetalleEncuesta = (id: number, tipo: string, nombre: string) => {
    setFormId(id);
    setFormType(tipo);
    setFormName(nombre);
    setOpenDialogDetalleEncuesta(true);
  };

  const fnCloseAllDialogs = () => {
    setOpenDialogDetalleEncuesta(false);
    setRespuestasASG([]);
    setRespuestasCAA([]);
    setFormId(undefined);
    setFormType(undefined);
    setFormName(undefined);
  };

  useEffect(() => {
    if (formId !== undefined && formType === 'ASG') {
      (async () => {
        const response = await EncuestasService.getAdmQuizDataASG(formId.toString());
        setRespuestasASG(response);
      })();

      setFormId(undefined);
    }
    if (formId !== undefined && formType === 'CAA') {
      (async () => {
        const response = await EncuestasService.getAdmQuizDataCAA(formId.toString());
        setRespuestasCAA(response);
      })();

      setFormId(undefined);
    }
  }, [formId]);

  useEffect(() => {
    (async () => {
      const response = await EncuestasService.getPieChart();
      setPieChartData(response);

      let totalEncuestas: number = 0;
      let totalEncuestados: number = 0;

      for (let i = 0; i < response.length; i++) {
        totalEncuestas += response[i].encuestas!;
        totalEncuestados += response[i].encuestados!;
      }

      let dataEncuestas: any[] = [];
      let dataEncuestados: any[] = [];

      for (let i = 0; i < response.length; i++) {
        const data = {
          value: Number(calcularPorcentaje(response[i].encuestas!, totalEncuestas, 1)),
          label: `F${i+1}`
        }

        dataEncuestas.push(data);
      }
      
      setChartDataEncuestas(dataEncuestas);

      for (let i = 0; i < response.length; i++) {
        const data = {
          value: Number(calcularPorcentaje(response[i].encuestados!, totalEncuestados, 1)),
          label: `F${i+1}`
        }

        dataEncuestados.push(data);
      }

      setChartDataEncuestados(dataEncuestados);
    })();

    (async () => {
      const dataDashboardsASG = await EncuestasService.getDashboardAdminASG();
      setDashboardsASG(dataDashboardsASG);
      // console.log('DASHBOARDS ADM ASG')
      // console.log(dataDashboardsASG);
    })();
    
    (async () => {
      const dataDashboardsCAA = await EncuestasService.getDashboardAdminCAA();
      setDashboardsCAA(dataDashboardsCAA);
      // console.log('DASHBOARDS ADM CAA')
      // console.log(dataDashboardsCAA);
    })();
  }, []);

  return (
    <>
      { piChartData.length > 0 && (
        <section>
          <Dialog open={openDialogDetalleEncuesta} onClose={fnCloseAllDialogs}>
            <DialogTitle align='center'>{formName}</DialogTitle>
            <DialogContent>
              <br/>
              <TableContainer component={Paper}>
                { formType === 'ASG' && (
                  <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Nº</TableCell>
                        <TableCell align="left">PREGUNTA</TableCell>
                        <TableCell align="center">NOTA</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {respuestasASG.map((respuesta: QuizDataASG, index) => (
                        <TableRow
                          key={`pregunta.${respuesta.indice}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="left">{respuesta.pregunta}</TableCell>
                          <TableCell align="center">{respuesta.nota}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
                { formType === 'CAA' && (
                  <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Nº</TableCell>
                        <TableCell align="left">PREGUNTA</TableCell>
                        <TableCell align="center">SÍ</TableCell>
                        <TableCell align="center">NO</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {respuestasCAA.map((respuesta: QuizDataCAA, index) => (
                        <TableRow
                          key={`pregunta.${respuesta.indice}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="left">{respuesta.pregunta}</TableCell>
                          <TableCell align="center">{fnGetPercentage(respuesta.si, respuesta.total)}%</TableCell>
                          <TableCell align="center">{fnGetPercentage(respuesta.no, respuesta.total)}%</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={fnCloseAllDialogs}>Cerrar</Button>
            </DialogActions>
          </Dialog>
          <div className="container">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="center" component="th" scope="row">
                      { chartDataEncuestas.length > 0 && (
                        <Box>
                          <Typography>ENCUESTAS CREADAS</Typography>
                          <PieChart
                            series={[
                              {
                                arcLabel: (item) => `${item.value}%`,
                                arcLabelMinAngle: 45,
                                data: chartDataEncuestas,
                              },
                            ]}
                            sx={{
                              [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontWeight: 'bold',
                              },
                            }}
                            {...size}
                          />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row" colSpan={4}>
                      { chartDataEncuestados.length > 0 && (
                        <Box>
                          <Typography>ENCUESTADOS</Typography>
                          <PieChart
                            series={[
                              {
                                arcLabel: (item) => `${item.value}%`,
                                arcLabelMinAngle: 45,
                                data: chartDataEncuestados,
                              },
                            ]}
                            sx={{
                              [`& .${pieArcLabelClasses.root}`]: {
                                fill: 'white',
                                fontWeight: 'bold',
                              },
                            }}
                            {...size}
                          />
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                  {piChartData.map((data, index) => (
                    <TableRow
                      key={data.formulario.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left" component="th" scope="row">F{index + 1}: {data.formulario!.nombre}</TableCell>
                      {data.formulario!.tipo === 'ASG' ? (
                        <TableCell align="left" component="th" scope="row">Tipo: ASIGNATURA</TableCell>
                      ) : (
                        <TableCell align="left" component="th" scope="row">Tipo: {data.formulario!.tipo}</TableCell>
                      )}
                      <TableCell align="center" component="th" scope="row">Encuestas: {data.encuestas}</TableCell>
                      <TableCell align="center" component="th" scope="row">Encuestados: {data.encuestados}</TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <Button variant="contained" size="small" startIcon={<PreviewRoundedIcon />} onClick={() => fnOpenDialogDetalleEncuesta(data.formulario.id, data.formulario!.tipo, `F${index + 1}: ${data.formulario!.nombre}`)}>Ver Detalle</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  {/* <TableRow>
                    <TableCell align="center" colSpan={3}>
                      <div className='pagination-container'>
                        <Stack spacing={5}>
                          <Pagination count={10} shape="rounded" page={1} />
                        </Stack>
                      </div>
                    </TableCell>
                  </TableRow> */}
                </TableFooter>
              </Table>
            </TableContainer>
            <br />
            { dashboardsASG.length > 0 &&
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">FORMULARIO</TableCell>
                        <TableCell align="center">ASIGNATURA</TableCell>
                        <TableCell align="center">NIVEL</TableCell>
                        <TableCell align="center">PREGUNTA</TableCell>
                        <TableCell align="center">PROMEDIO</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dashboardsASG.map((dataArray: DashboardASG[], arrayIndex) => (
                        dataArray.map((data: DashboardASG, index) => (
                          <TableRow
                            key={`${arrayIndex}-${index}-asg`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="left" component="th" scope="row">
                              {data.formulario}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                              {data.asignatura}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                              {data.nivel}
                            </TableCell>
                            <TableCell align="left" component="th" scope="row">
                              {data.pregunta}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                              {data.promedio}
                            </TableCell>
                          </TableRow>
                        ))
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
              </>
            }
            { dashboardsCAA.length > 0 &&
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">FORMULARIO</TableCell>
                        <TableCell align="center">NIVEL</TableCell>
                        <TableCell align="center">PREGUNTA</TableCell>
                        <TableCell align="center">SÍ</TableCell>
                        <TableCell align="center">NO</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dashboardsCAA.map((dataArray: DashboardCAA[], arrayIndex) => (
                        dataArray.map((data: DashboardCAA, index) => (
                          <TableRow
                            key={`${arrayIndex}-${index}-caa`}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="left" component="th" scope="row">
                              {data.formulario}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                              {data.nivel}
                            </TableCell>
                            <TableCell align="left" component="th" scope="row">
                              {data.pregunta}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                              {`${data.si}%`}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                            {`${data.no}%`}
                            </TableCell>
                          </TableRow>
                        ))
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
              </>
            }
          </div>
        </section>
      )}
    </>
  );
};

const InicioAdm = () => {
  return(
    <>
      <Page breadcrumbs={breadcrumbs} children={<Content />} />
    </>
  );
};

export default InicioAdm;
