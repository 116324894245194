import axios from 'axios';

import { GLOBAL_VARS } from "../config/global-vars.config";
import { Colegio } from './colegios.service';
import { Nivel } from './niveles.service';
import { Estudiante } from './estudiantes.service';

export interface Curso {
  id?: string | undefined;
  colegio?: Colegio | undefined;
  temporada?: number;
  nivel?: Nivel | undefined;
  letra?: string;
  creado?: string;
  activo?: number;
};

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');
  config.headers['Authorization'] = token;

  return config;
});

class CursosService {
  static async listar() {
    let cursos: Curso[] = [];

    try {
      const response = await axios.get<Curso[]>(`/cursos`);

      cursos = response.data;
    } catch (e) {
      console.error(e);
    } finally {
      return cursos;
    }
  };

  static async obtenerAlumnosDelCurso(cursoId: string | undefined) {
    try {
      const response = await axios.get<Estudiante[]>(`/cursos/estudiantes/${cursoId}`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async obtenerAlumnosFueraDelCurso(cursoId: string | undefined) {
    try {
      const response = await axios.get<Estudiante[]>(`/cursos/estudiantes/not-in/${cursoId}`);

      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  static async crear(curso: Curso | undefined) {
    try {
      const response = await axios.post(`/cursos`, curso, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async eliminar(id: string | undefined) {
    try {
      const response = await axios.delete(`/cursos/${id}`);

      if (response.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  static async actualizar(curso: Curso) {
    try {
      const response = await axios.patch(`/cursos/${curso.id}`, curso, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }

  static async actualizarEstudiantes(id: string, estudiantes: Estudiante[]) {
    try {
      const response = await axios.patch(`/cursos/estudiantes/${id}`, estudiantes, { headers: {} });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export default CursosService;
