import { Link } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem, FormHelperText } from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import { useRoleCheck, getUserId } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';

import './usuarios.page.css';
import UsuariosService, { Usuario } from "../services/usuarios.service";
import { useEffect, useState } from "react";
import ColegiosService, { Colegio } from "../services/colegios.service";
import { rutFormatter, formatRutForDisplay, formatRutForInput, rutValido, insertDash, emailValido, formatEmailForDisplay, formatEmailForInput } from '../helpers/validations-form.helper';

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Usuarios</Typography>
];

interface Props {
  rowsPerPage: number;
  page: number;
};

const Content = ({rowsPerPage, page}: Props) => {
  const [refresh, setRefresh] = useState<number>(0);
  const [usuarioNuevo, setUsuarioNuevo] = useState(false);
  const [openDialogCrearUsuario, setOpenDialogCrearUsuario] = useState(false);
  const [openDialogModificarUsuario, setOpenDialogModificarUsuario] = useState(false);
  const [openDialogVerDetalle, setOpenDialogVerDetalle] = useState(false);
  const [colegios, setColegios] = useState<Colegio[]>([]);
  const [colegio, setColegio] = useState<Colegio | undefined>(undefined);
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [usuario, setUsuario] = useState<Usuario | undefined>();
  const [createId, setCreateId] = useState<string>('');

  const [rut, setRut] = useState<string>('');
  const [rutError, setRutError] = useState<boolean>(false);
  const [rutErrorMessage, setRutErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [clave, setClave] = useState<string>('');
  const [claveError, setClaveError] = useState<boolean>(false);
  const [claveErrorMessage, setClaveErrorMessage] = useState<string>('');
  const [nombres, setNombres] = useState<string>('');
  const [nombresError, setNombresError] = useState<boolean>(false);
  const [nombresErrorMessage, setNombresErrorMessage] = useState<string>('');
  const [apellidos, setApellidos] = useState<string>('');
  const [apellidosError, setApellidosError] = useState<boolean>(false);
  const [apellidosErrorMessage, setApellidosErrorMessage] = useState<string>('');
  const [rol, setRol] = useState<string>('');
  const [rolError, setRolError] = useState<boolean>(false);
  const [rolErrorMessage, setRolErrorMessage] = useState<string>('');
  
  const [pass, setPass] = useState<string>('');
  const [colegioId, setColegioId] = useState<string>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const [updateId, setUpdateId] = useState<string>('');

  const fnOpenDialogCrearUsuario = () => {
    setUsuarioNuevo(true);
    setColegioId('')
    setOpenDialogCrearUsuario(true);
  };
  
  const fnOpenDialogModificarUsuario = (id: string, email: string, nombres: string, apellidos: string, rol: string, colegio?: Colegio) => {
    setUsuarioNuevo(false);
    setRut(id);
    setEmail(email);
    setNombres(nombres);
    setApellidos(apellidos);
    setRol(rol);
    setColegio(colegio);
    (colegio === null || colegio == undefined) ? setColegioId('') : setColegioId(colegio.id);
    setOpenDialogModificarUsuario(true);
  };
  
  const fnOpenDialogVerDetalleUsuario = (id: string, email: string, nombres: string, apellidos: string, rol: string, colegio?: Colegio) => {
    setRut(id);
    setEmail(email);
    setNombres(nombres);
    setApellidos(apellidos);
    setRol(rol);
    setColegio(colegio);
    setOpenDialogVerDetalle(true);
  };

  const fnCloseAllDialogs = () => {
    setOpenDialogCrearUsuario(false);
    setOpenDialogModificarUsuario(false);
    setOpenDialogVerDetalle(false);
    setCreateId('');
    fnCleanFormAddUser();
  };

  const fnCleanFormAddUser = () => {
    setRut('');
    setRutError(false);
    setEmail('');
    setEmailError(false);
    setClave('');
    setClaveError(false);
    setNombres('');
    setNombresError(false);
    setApellidos('');
    setApellidosError(false);
    setRol('');
    setRolError(false);
    // TODO: Falta agregar la validación para colegio.
  }

  const fnCrearUsuario = () => {
    setUpdateId('');
    setDeleteId('');

    if (!rutError && !emailError) {
      const colegio: Colegio | undefined = ( colegioId === '') ? undefined : { id: colegioId, nombre: '' };

      const usuario: Usuario = {
        id: rutFormatter(rut),
        email: email,
        nombres: nombres,
        apellidos: apellidos,
        pass: clave,
        rol: rol,
        colegio: colegio
      };
      
      setUsuario(usuario);
      fnCloseAllDialogs();
    } else {
      // TODO: Implementar un mensaje de error.
      setRutError(true);
    }
  };
  
  const fnActualizarUsuario = () => {
    setCreateId('');
    setDeleteId('');

    const colegio: Colegio | undefined = ( colegioId === '') ? undefined : { id: colegioId, nombre: '' };
    setUpdateId(rut);

    const usuario: Usuario = {
      id: rut,
      email: email,
      nombres: nombres,
      apellidos: apellidos,
      pass: pass,
      rol: rol,
      colegio: colegio
    };
    
    setUsuario(usuario);
    fnCloseAllDialogs();
  };

  const fnHandleRutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setRut(inputValue);

    if (inputValue === '') {
      setRutError(true);
      setRutErrorMessage('Campo Obligatorio');
    } else if (!rutValido(inputValue)) {
      setRutError(true);
      setRutErrorMessage('Rut Inválido');
    } else {
      setRutError(false);
      setRutErrorMessage('');
    }
  };

  const fnHandleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    
    if (inputValue === '') {
      setEmailError(true);
      setEmailErrorMessage('Campo Obligatorio');
    } else if (!emailValido(inputValue)) {
      setEmailError(true);
      setEmailErrorMessage('Email Inválido');
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }
  };
  
  const fnHandleClaveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setClave(inputValue);
    
    if (inputValue === '') {
      setClaveError(true);
      setClaveErrorMessage('Campo Obligatorio');
    } else {
      setClaveError(false);
      setClaveErrorMessage('');
    }
  };
  
  const fnHandleNombresChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setNombres(inputValue);
    
    if (inputValue === '') {
      setNombresError(true);
      setNombresErrorMessage('Campo Obligatorio');
    } else {
      setNombresError(false);
      setNombresErrorMessage('');
    }
  };
  
  const fnHandleApellidosChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setApellidos(inputValue);
    
    if (inputValue === '') {
      setApellidosError(true);
      setApellidosErrorMessage('Campo Obligatorio');
    } else {
      setApellidosError(false);
      setApellidosErrorMessage('');
    }
  };
  
  const fnHandleRolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setRol(inputValue);
    
    if (inputValue === '') {
      setRolError(true);
      setRolErrorMessage('Campo Obligatorio');
    } else {
      setRolError(false);
      setRolErrorMessage('');
    }
  };

  useEffect(() => {
    if (deleteId !== '') {
      (async () => {
        const response = await UsuariosService.eliminar(deleteId);
        setDeleteId('');
        setRefresh((refresh) => refresh + 1);
      })();
    }

    if (typeof usuario === 'object') {
      if (usuarioNuevo) {
        (async () => {
          const response = await UsuariosService.crear(usuario);
          // console.log(response);
          if (response.status === 201) {
            // console.log('Usuario creado correctamente.');
          }
          
          if (response.status === 409) {
            // console.log('Usuario duplicado.');
          }

          if (response.status === 500) {
            // console.log('Error de servidor.');
          }

          setRefresh((refresh) => refresh + 1);
        })();

        setCreateId('');
        setEmail('');
        setNombres('');
        setApellidos('');
        setPass('');
        setRol('');
        setColegioId('');
        setUsuarioNuevo(false);
      }
      
      if (updateId !== '') {
        (async () => {
          const response = await UsuariosService.actualizar(usuario);
          setRefresh((refresh) => refresh + 1);
        })();

        setUpdateId('');
      }

      setUsuario(undefined);
    }
  }, [usuario, deleteId]);

  useEffect(() => {
    (async () => {
      const dataColegios = await ColegiosService.listar();
      setColegios(dataColegios);
    
      const data = await UsuariosService.listar();
      setUsuarios(data);
    })();
  }, [refresh]);

  return (
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrearUsuario} >Crear</Button>
      <Dialog open={openDialogCrearUsuario} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR USUARIO</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="rut"
              label="Rut"
              type="text"
              fullWidth
              variant="standard"
              value={formatRutForDisplay(rut)}
              onChange={fnHandleRutChange}
              inputProps={{ maxLength: 12 }}
              error={rutError} // Establece el estado de error
              helperText={rutError ? rutErrorMessage : ''} // Muestra un mensaje de ayuda si hay un error
              required // Marca el campo como obligatorio
            />
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="text"
              fullWidth
              variant="standard"
              value={formatEmailForDisplay(email)}
              onChange={fnHandleEmailChange}
              inputProps={{ maxLength: 100 }}
              error={emailError} // Establece el estado de error
              helperText={emailError ? emailErrorMessage : ''} // Muestra un mensaje de ayuda si hay un error
              required // Marca el campo como obligatorio
            />
            <TextField
              autoFocus
              margin="dense"
              id="clave"
              label="Clave"
              type="text"
              fullWidth
              variant="standard"
              value={clave}
              onChange={fnHandleClaveChange}
              error={claveError} // Establece el estado de error
              helperText={claveError ? claveErrorMessage : ''} // Muestra un mensaje de ayuda si hay un error
              required // Marca el campo como obligatorio
            />
            <TextField
              autoFocus
              margin="dense"
              id="nombres"
              label="Nombres"
              type="text"
              fullWidth
              variant="standard"
              value={nombres}
              onChange={fnHandleNombresChange}
              error={nombresError} // Establece el estado de error
              helperText={nombresError ? nombresErrorMessage : ''} // Muestra un mensaje de ayuda si hay un error
              required // Marca el campo como obligatorio
            />
            <TextField
              autoFocus
              margin="dense"
              id="apellidos"
              label="Apellidos"
              type="text"
              fullWidth
              variant="standard"
              value={apellidos}
              onChange={fnHandleApellidosChange}
              error={apellidosError} // Establece el estado de error
              helperText={apellidosError ? apellidosErrorMessage : ''} // Muestra un mensaje de ayuda si hay un error
              required // Marca el campo como obligatorio
            />
            <FormLabel id="demo-radio-buttons-group-label">Rol</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={fnHandleRolChange}
            >
              <FormControlLabel value="ADM" control={<Radio required />} label="Administrador" />
              <FormControlLabel value="ASG" control={<Radio required />} label="Profesor" />
              <FormControlLabel value="CAA" control={<Radio required />} label="CAA" />
            </RadioGroup>
            {rolError && <FormHelperText error>{rolErrorMessage}</FormHelperText>}
          { rol !== '' && rol !== 'ADM' && (
            <>
              <FormLabel id="demo-simple-select-standard-label">Colegio</FormLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={colegioId}
                onChange={(event) => setColegioId(event.target.value)}
                label="Colegio"
              >
                {colegios.map((colegio: Colegio) => (
                  <MenuItem key={`cole-${colegio.id}`} value={colegio.id}>{colegio.nombre}</MenuItem>
                ))}
              </Select>
            </>
          ) }
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrearUsuario}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogModificarUsuario} onClose={fnCloseAllDialogs}>
        <DialogTitle>MODIFICAR USUARIO</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="rut"
              label="Rut"
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={rut}
            />
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="text"
              fullWidth
              variant="standard"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="clave"
              label="Clave"
              type="text"
              fullWidth
              variant="standard"
              value={pass}
              onChange={e => setPass(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="nombres"
              label="Nombres"
              type="text"
              fullWidth
              variant="standard"
              value={nombres}
              onChange={e => setNombres(e.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="apellidos"
              label="Apellidos"
              type="text"
              fullWidth
              variant="standard"
              value={apellidos}
              onChange={e => setApellidos(e.target.value)}
            />
            <FormLabel id="demo-radio-buttons-group-label">Rol</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              onChange={e => setRol(e.target.value)}
            >
              { rol === 'ADM' && (
                <>
                  <FormControlLabel value="ADM" control={<Radio />} label="Administrador" checked />
                  <FormControlLabel value="ASG" control={<Radio />} label="Profesor" />
                  <FormControlLabel value="CAA" control={<Radio />} label="CAA" />
                </>
              )}
              { rol === 'ASG' && (
                <>
                  <FormControlLabel value="ADM" control={<Radio />} label="Administrador" />
                  <FormControlLabel value="ASG" control={<Radio />} label="Profesor" checked />
                  <FormControlLabel value="CAA" control={<Radio />} label="CAA" />
                </>
              )}
              { rol === 'CAA' && (
                <>
                  <FormControlLabel value="ADM" control={<Radio />} label="Administrador" />
                  <FormControlLabel value="ASG" control={<Radio />} label="Profesor" />
                  <FormControlLabel value="CAA" control={<Radio />} label="CAA" checked />
                </>
              )}
            </RadioGroup>
            { rol !== '' && rol !== 'ADM' && (
              <>
                <FormLabel id="demo-simple-select-standard-label">Colegio</FormLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={colegioId}
                  onChange={(event) => setColegioId(event.target.value)}
                  label="Colegio"
                  defaultValue={colegioId}
                >
                  {
                    colegios.map((col: Colegio) => (
                      <MenuItem key={`cole-${col.id}`} value={col.id}>{col.nombre}</MenuItem>
                    ))
                  }
                </Select>
              </>
            ) }
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnActualizarUsuario}>Guardar</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogVerDetalle} onClose={fnCloseAllDialogs}>
        <DialogTitle>INFORMACIÓN DE USUARIO</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="rut"
              label="Rut"
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={rut}
            />
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={email}
            />
            <TextField
              autoFocus
              margin="dense"
              id="nombres"
              label="Nombres"
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={nombres}
            />
            <TextField
              autoFocus
              margin="dense"
              id="apellidos"
              label="Apellidos"
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={apellidos}
            />
            <TextField
              autoFocus
              margin="dense"
              id="rol"
              label="Rol"
              type="text"
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
              }}
              value={rol}
            />
            { colegio !== undefined && colegio !== null && (
                <TextField
                  autoFocus
                  margin="dense"
                  id="colegio"
                  label="Colegio"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={colegio.nombre}
                />
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCloseAllDialogs}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>RUT</TableCell>
                <TableCell>NOMBRE</TableCell>
                <TableCell align="center">ROL</TableCell>
                <TableCell align="center">COLEGIO</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usuarios.map((usuario) => (
                <TableRow
                  key={`usu-${usuario.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{usuario.id}</TableCell>
                  <TableCell component="th" scope="row">
                    {usuario.nombres} {usuario.apellidos}
                  </TableCell>
                  <TableCell align="center">{usuario.rol}</TableCell>
                  <TableCell align="center">
                    {
                      usuario.colegio === undefined || usuario.colegio === null ? ('N/A') : usuario.colegio.nombre                      
                    }
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained" size="small" startIcon={<InfoRoundedIcon />} onClick={() => fnOpenDialogVerDetalleUsuario(
                      usuario.id, usuario.email, usuario.nombres, usuario.apellidos, usuario.rol, usuario.colegio
                    )}>Ver Detalle</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<SettingsRoundedIcon />} onClick={() => fnOpenDialogModificarUsuario(
                      usuario.id, usuario.email, usuario.nombres, usuario.apellidos, usuario.rol, usuario.colegio
                    )}>Modificar</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={() => setDeleteId(usuario.id)} disabled={usuario.id! === getUserId()}>Eliminar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              {/* <TableRow>
                <TableCell align="center" colSpan={5}>
                  <div className='pagination-container'>
                    <Stack spacing={5}>
                      <Pagination count={10} shape="rounded" page={1} />
                    </Stack>
                  </div>
                </TableCell>
              </TableRow> */}
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

const Usuarios = () => {
  const rowsPerPage = 10;
  const page = 1;
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content rowsPerPage={rowsPerPage} page={page} />} />
  );
};

export default Usuarios;
