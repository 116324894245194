import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, TableFooter, Stack, Pagination, Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemText, Divider, Box } from "@mui/material";

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import { useRoleCheck } from '../hooks/use-role-check.hook';
import Page from '../components/page/page.component';
import CursosService, { Curso } from '../services/cursos.service';
import NivelesService, { Nivel } from '../services/niveles.service';

import './cursos.page.css';
import { Estudiante } from "../services/estudiantes.service";

const breadcrumbs = [
  <Link key="1" to="/">Inicio</Link>,
  <Typography key="2" color="text.primary">Cursos</Typography>
];

const Content = () => {
  useRoleCheck(['ADM', 'ASG', 'CAA']);

  const [add, setAdd] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [removeEstudiante, setRemoveEstudiante] = useState<string>('');
  const [refreshPage, setRefreshPage] = useState<number>(0);
  const [refreshEstudiantes, setRefreshEstudiantes] = useState<number>(0);
  const [openDialogCrearCurso, setOpenDialogCrearCurso] = useState(false);
  const [openDialogListarEstudiantes, setOpenDialogListarEstudiantes] = useState(false);
  const [cursos, setCursos] = useState<Curso[]>([]);
  const [estudiantes, setEstudiantes] = useState<Estudiante[]>([]);
  const [estudiantesCurso, setEstudiantesCurso] = useState<Estudiante[]>([]);
  const [curso, setCurso] = useState<Curso | undefined>();
  const [id, setId] = useState<string | undefined>();
  const [temporada, setTemporada] = useState<number | undefined>();
  const [niveles, setNiveles] = useState<Nivel[]>([]);
  const [nivelId, setNivelId] = useState<string | undefined>();
  const [letra, setLetra] = useState<string | undefined>();

  const fnOpenDialogCrearCurso = () => {
    setOpenDialogCrearCurso(true);
  };

  const fnOpenDialogListarEstudiantes = (cursoId: string | undefined) => {
    setId(cursoId);
    setRefreshEstudiantes((refreshEstudiantes) => refreshEstudiantes + 1);
    setOpenDialogListarEstudiantes(true);
  };

  const fnCrearCurso = () => {
    setAdd(true);

    const curso: Curso = {
      temporada: temporada,
      nivel: {
        id: nivelId
      },
      letra: letra
    };
    
    setCurso(curso);
    fnCloseAllDialogs();
  };

  const fnGuardarCurso = () => {
    setUpdate(true);
    fnCloseAllDialogs();
  };
  
  const fnCloseAllDialogs = () => {
    setOpenDialogCrearCurso(false);
    setOpenDialogListarEstudiantes(false);
  };

  const fnRemove = (id: string | undefined) => {
    setRemove(true);
    setId(id);
  };
  
  const fnClean = () => {
    setAdd(false);
    setUpdate(false);
    setRemove(false);
    setId(undefined);
    setTemporada(undefined);
    setLetra(undefined);
    setCurso(undefined);
  };

  useEffect(() => {
    if (remove) {
      (async () => {
        const response = await CursosService.eliminar(id);
        setRefreshPage((refreshPage) => refreshPage + 1);
      })();

      fnClean();
    }

    if (add) {
      (async () => {
        const response = await CursosService.crear(curso);
        setRefreshPage((refreshPage) => refreshPage + 1);
      })();

      fnClean();
    }

    if (update) {
      (async () => {
        const response = await CursosService.actualizarEstudiantes(id!, estudiantesCurso);
        setRefreshPage((refreshPage) => refreshPage + 1);
      })();

      fnClean();
    }
  }, [curso, id, update]);

  const moverEstudiante = (sourceList: Estudiante[], targetList: Estudiante[], student: Estudiante) => {
    const updatedSourceList = sourceList.filter((s) => s !== student);
    const updatedTargetList = [...targetList, student];
    return [updatedSourceList, updatedTargetList];
  };

  const moverEstudianteCursoAColegio = (estudiante: Estudiante) => {
    const [listaActualizadaEstudiantesCurso, listaActualizadaEstudiantes] = moverEstudiante(estudiantesCurso, estudiantes, estudiante);
    setEstudiantesCurso(listaActualizadaEstudiantesCurso);
    setEstudiantes(listaActualizadaEstudiantes);
  };

  const moverEstudianteColegioACurso = (estudiante: Estudiante) => {
    const [listaActualizadaEstudiantes, listaActualizadaEstudiantesCurso] = moverEstudiante(estudiantes, estudiantesCurso, estudiante);
    setEstudiantes(listaActualizadaEstudiantes);
    setEstudiantesCurso(listaActualizadaEstudiantesCurso);
  };

  useEffect(() => {
    (async () => {
      const estudiantesDelCurso = await CursosService.obtenerAlumnosDelCurso(id);
      const estudiantes = await CursosService.obtenerAlumnosFueraDelCurso(id);
      setEstudiantesCurso(estudiantesDelCurso);
      setEstudiantes(estudiantes);
    })();
  }, [refreshEstudiantes]);
  
  useEffect(() => {
    (async () => {
      const dataCursos = await CursosService.listar();
      setCursos(dataCursos);
      const dataNiveles = await NivelesService.listar();
      setNiveles(dataNiveles);
      setEstudiantes([]);
    })();
  }, [refreshPage]);

  return (
    <section>
      <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={fnOpenDialogCrearCurso} >Crear</Button>
      <Dialog open={openDialogCrearCurso} onClose={fnCloseAllDialogs}>
        <DialogTitle>CREAR CURSO</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Complete los siguientes datos:
          </DialogContentText><br /> */}
          <FormControl variant="standard" sx={{ m: 1, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              id="temporada"
              label="Temporada"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setTemporada(Number(e.target.value))}
            />
            <FormControl variant="standard">
              <InputLabel id="nivel-label">Nivel</InputLabel>
              <Select
                labelId="nivel-label"
                id="nivel"
                value={nivelId}
                label="Nivel"
                onChange={e => setNivelId(String(e.target.value))}
              >
                {niveles.map((nivel) => (
                  <MenuItem key={nivel.id} value={nivel.id}>{nivel.nombre}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              id="letra"
              label="Letra"
              type="text"
              fullWidth
              variant="standard"
              onChange={e => setLetra(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCrearCurso}>Crear</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogListarEstudiantes} onClose={fnCloseAllDialogs} maxWidth="lg">
        <DialogTitle>MODIFICAR CURSO</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="space-between">
            <Box sx={{
              minWidth: '500px', // Ancho fijo
              height: '500px', // Alto fijo
              border: '0px solid black', // Borde para visualización
              display: 'flex', // Mostrar los elementos internos en una fila (por defecto)
              flexDirection: 'column', // Cambiar a columna para alinear títulos arriba
            }} padding="16px" flex="1">
              <Typography variant="h6">Alumnos del Curso</Typography>
              <List>
                {estudiantesCurso.map((estudiante: Estudiante) => (
                  <ListItem key={`${estudiante.id}+_a`}>
                    <Button variant="contained" size="small" onClick={() => moverEstudianteCursoAColegio(estudiante)} sx={{ marginRight: '8px' }}>
                      <PersonRemoveIcon sx={{ marginLeft: '0px' }} />
                    </Button>
                    <ListItemText primary={`${estudiante.id} ${estudiante.nombres} ${estudiante.apellidos}`}/>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box sx={{
              minWidth: '500px', // Ancho fijo
              height: '500px', // Alto fijo
              border: '0px solid black', // Borde para visualización
              display: 'flex', // Mostrar los elementos internos en una fila (por defecto)
              flexDirection: 'column', // Cambiar a columna para alinear títulos arriba
            }} padding="16px" flex="1">
              <Typography variant="h6">Alumnos Disponibles</Typography>
              <List>
                {estudiantes.map((estudiante: Estudiante) => (
                  <ListItem key={`${estudiante.id}+_b`}>
                    <Button variant="contained" size="small" onClick={() => moverEstudianteColegioACurso(estudiante)} sx={{ marginRight: '8px' }}>
                      <PersonAddIcon sx={{ marginLeft: '0px' }} />
                    </Button>
                    <ListItemText primary={`${estudiante.id} ${estudiante.nombres} ${estudiante.apellidos}`}/>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnGuardarCurso}>Guardar Cambios</Button>
          <Button onClick={fnCloseAllDialogs}>Cancelar</Button>
        </DialogActions>
      </Dialog>
      <div className="container">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell align="center">ID</TableCell> */}
                <TableCell align="center">TEMPORADA</TableCell>
                <TableCell align="center">NOMBRE</TableCell>
                <TableCell align="center">COLEGIO</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {cursos.map((curso: Curso) => (
                <TableRow
                  key={curso.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {/* <TableCell align="center" component="th" scope="row">
                    {curso.id}
                  </TableCell> */}
                  <TableCell align="center" component="th" scope="row">
                    {curso.temporada}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {curso.nivel?.nombre} {curso.letra}
                  </TableCell>
                  <TableCell align="center">
                    {curso.colegio?.nombre}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained" size="small" startIcon={<AddCircleRoundedIcon />} onClick={ () => fnOpenDialogListarEstudiantes(curso?.id) }>Estudiantes</Button>&nbsp;
                    <Button variant="contained" size="small" startIcon={<DeleteForeverRoundedIcon />} onClick={ () => fnRemove(curso.id) }>Eliminar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              {/* <TableRow>
                <TableCell align="center" colSpan={3}>
                  <div className='pagination-container'>
                    <Stack spacing={5}>
                      <Pagination count={10} shape="rounded" page={1} />
                    </Stack>
                  </div>
                </TableCell>
              </TableRow> */}
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
};

const Cursos = () => {
  return (
    <Page breadcrumbs={breadcrumbs} children={<Content />} />
  );
};

export default Cursos;
