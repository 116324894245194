import axios from 'axios';
import jwt_decode from "jwt-decode";

import { GLOBAL_VARS } from "../config/global-vars.config";

export interface Credential {
  user: string;
  pass: string;
};

export interface Payload {
  uid: string;
  role: string;
  college?: string;
};

// Configuración de Axios
axios.defaults.baseURL = GLOBAL_VARS.API;

class AuthService {
  static async logIn(credential: Credential) {
    try {
      const response = await axios.post(`/auth`, credential, { headers: {} });

      if (response.status === 202) {
        const { token } = response.data;
        const decodedToken: Payload = await jwt_decode(token);

        window.sessionStorage.setItem('uid', decodedToken.uid);
        window.sessionStorage.setItem('role', decodedToken.role);

        if (decodedToken.college) {
          window.sessionStorage.setItem('college', decodedToken.college);
        }

        window.sessionStorage.setItem('token', token);

        return true;
      } else if (response.status === 202) {
        const { message } = response.data;
        // console.log(message);
      } else {
        throw new Error('Status code ERROR');
      }
    } catch (e) {
      console.error(e);

      return false;
    }
  }

  static logOut() {
    try {
      window.sessionStorage.removeItem('uid');
      window.sessionStorage.removeItem('role');
      window.sessionStorage.removeItem('college');
      window.sessionStorage.removeItem('token');

      return true;
    } catch (e) {
      console.error(e);

      return false;
    }
  }
}

export default AuthService;
